import React, { useState, useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { sortBy } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'

import OpenTasksByProject from './OpenTasksByProject'
import { resetLoadingData, setLaterTasksExpanded, setSomedayTasksExpanded } from '../../../redux/actions'

export default function OpenTasksViewAllProjects() {
    const dispatch = useDispatch()
    const smallScreenNavigation = useSelector(state => state.smallScreenNavigation)
    const isMiddleScreen = useSelector(state => state.isMiddleScreen)
    const templateProjectIds = useSelector(state => state.loggedUser.templateProjectIds)
    const archivedProjectIds = useSelector(state => state.loggedUser.archivedProjectIds)
    const guideProjectIds = useSelector(state => state.loggedUser.guideProjectIds)
    const projectIds = useSelector(state => state.loggedUser.projectIds)
    const loggedUserProjectsMap = useSelector(state => state.loggedUserProjectsMap)
    const [projectsHaveTasksInFirstDay, setProjectsHaveTasksInFirstDay] = useState({})

    const normalProjectIds = projectIds.filter(
        projectId =>
            loggedUserProjectsMap[projectId] &&
            !templateProjectIds.includes(projectId) &&
            !archivedProjectIds.includes(projectId) &&
            !guideProjectIds.includes(projectId)
    )

    const sortedLoggedUserProjectIds = [
        ...sortBy(normalProjectIds, [projectId => loggedUserProjectsMap[projectId].name.toLowerCase()]),
        ...sortBy(
            guideProjectIds.filter(projectId => !!loggedUserProjectsMap[projectId]),
            [projectId => loggedUserProjectsMap[projectId].name.toLowerCase()]
        ),
    ]

    useEffect(() => {
        dispatch(resetLoadingData())
        return () => {
            dispatch(resetLoadingData())
        }
    }, [])

    useEffect(() => {
        return () => {
            dispatch([setLaterTasksExpanded(false), setSomedayTasksExpanded(false)])
        }
    }, [])

    let areFirstProject = false

    return (
        <View
            style={[
                localStyles.container,
                smallScreenNavigation
                    ? localStyles.containerForMobile
                    : isMiddleScreen && localStyles.containerForTablet,
            ]}
        >
            {sortedLoggedUserProjectIds.map(projectId => {
                let thisProjectIsTheFirstProject = false
                if (projectsHaveTasksInFirstDay[projectId] && !areFirstProject) {
                    areFirstProject = true
                    thisProjectIsTheFirstProject = true
                }
                const isLastProject = projectId === sortedLoggedUserProjectIds[sortedLoggedUserProjectIds.length - 1]

                return (
                    <OpenTasksByProject
                        key={projectId}
                        projectId={projectId}
                        firstProject={thisProjectIsTheFirstProject}
                        isLastProject={isLastProject}
                        sortedLoggedUserProjectIds={sortedLoggedUserProjectIds}
                        setProjectsHaveTasksInFirstDay={setProjectsHaveTasksInFirstDay}
                    />
                )
            })}
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        paddingHorizontal: 104,
        backgroundColor: 'white',
        marginBottom: 32,
    },
    containerForMobile: {
        paddingHorizontal: 16,
    },
    containerForTablet: {
        paddingHorizontal: 56,
    },
})
