import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { isEqual, sortBy } from 'lodash'

import SkillsByProject from './SkillsByProject/SkillsByProject'

export default function SkillsAllProjects({ setDismissibleRefs, closeEdition, closeAllEdition, openEdition }) {
    const loggedUserId = useSelector(state => state.loggedUser.uid)
    const archivedProjectIds = useSelector(state => state.loggedUser.archivedProjectIds)
    const templateProjectIds = useSelector(state => state.loggedUser.templateProjectIds)

    const projectsData = useSelector(
        state =>
            state.loggedUserProjects.map(project => {
                const { index, name, id } = project
                return { index, name, id }
            }),
        isEqual
    )

    const projects = projectsData.filter(
        project => !templateProjectIds.includes(project.id) && !archivedProjectIds.includes(project.id)
    )

    const normalProjects = projects.filter(project => !project.parentTemplateId)
    const guides = projects.filter(project => !!project.parentTemplateId)

    const sortedProjectsData = [
        ...sortBy(normalProjects, [project => project.name.toLowerCase()]),
        ...sortBy(guides, [project => project.name.toLowerCase()]),
    ]

    return (
        <View>
            {sortedProjectsData.map(projectData => {
                const { id, index } = projectData
                return (
                    <SkillsByProject
                        key={id}
                        projectIndex={index}
                        projectId={id}
                        userId={loggedUserId}
                        setDismissibleRefs={setDismissibleRefs}
                        openEdition={openEdition}
                        closeEdition={closeEdition}
                        closeAllEdition={closeAllEdition}
                    />
                )
            })}
        </View>
    )
}
