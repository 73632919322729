import React, { useEffect, useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import styles, { colors } from '../../../styles/global'
import Backend from '../../../../utils/BackendBridge'
import CloseButton from '../../../FollowUp/CloseButton'
import { applyPopoverWidth, MODAL_MAX_HEIGHT_GAP } from '../../../../utils/HelperFunctions'
import { ESTIMATIONS_MODAL_ID, removeModal, storeModal } from '../../../ModalsManager/modalsManager'
import CustomScrollView from '../../../UIControls/CustomScrollView'
import useWindowSize from '../../../../utils/useWindowSize'
import { translate } from '../../../../i18n/TranslationService'
import {
    ESTIMATION_OPTIONS,
    ESTIMATION_TYPE_TIME,
    getEstimationTypeByProjectId,
} from '../../../../utils/EstimationHelper'
import EstimationModalBackButton from './EstimationModalBackButton'
import EstimationModalItem from './EstimationModalItem'
import CustomEstimationItem from './CustomEstimationItem'
import CustomEstimationModal from './CustomEstimationModal'
import { setFutureEstimations } from '../../../../utils/backends/Tasks/tasksFirestore'

const EstimationModal = ({
    task,
    stepId,
    projectId,
    estimation,
    notUpdateTask,
    closePopover,
    setEstimationFn,
    showBackButton,
    multipleTasks,
    tasks,
    setIsLoading,
}) => {
    const [width, height] = useWindowSize()
    const [selectedEstimation, setSelectedEstimation] = useState(
        estimation ? estimation : task.estimations ? (task.estimations[stepId] ? task.estimations[stepId] : 0) : 0
    )
    const [showCustomForm, setShowCustomForm] = useState(false)
    const estimationType = getEstimationTypeByProjectId(projectId)

    const selectEstimation = async estimationValue => {
        if (selectedEstimation !== estimationValue) {
            if (!notUpdateTask) {
                if (!multipleTasks) {
                    setFutureEstimations(projectId, task.id, task, stepId, estimationValue)
                } else {
                    setIsLoading(true)
                    Backend.setFutureEstimationsMultiple(tasks, stepId, estimationValue).then(() => setIsLoading(false))
                }
            }

            setSelectedEstimation(estimationValue)
        }
        closePopover(estimationValue)
        return false
    }

    const onPress = (e, value) => {
        e?.preventDefault()
        e?.stopPropagation()

        if (setEstimationFn !== undefined) {
            closePopover(value)
            setEstimationFn(value)
        } else {
            selectEstimation(value)
        }
        return false
    }

    useEffect(() => {
        storeModal(ESTIMATIONS_MODAL_ID)
        return () => {
            removeModal(ESTIMATIONS_MODAL_ID)
        }
    }, [])

    const showCustomEstimationForm = e => {
        e?.preventDefault()
        e?.stopPropagation()
        setShowCustomForm(true)
    }

    const setCustomEstimation = (estimationValue, escape = false) => {
        setShowCustomForm(false)

        if (estimationValue > 0 && !escape) {
            onPress(null, estimationValue)
        }
    }

    return estimationType === ESTIMATION_TYPE_TIME && showCustomForm ? (
        <CustomEstimationModal setCustomEstimation={setCustomEstimation} initialEstimation={selectedEstimation} />
    ) : (
        <View style={[localStyles.parent, applyPopoverWidth(), { maxHeight: height - MODAL_MAX_HEIGHT_GAP }]}>
            <CustomScrollView showsVerticalScrollIndicator={false}>
                <View style={localStyles.container}>
                    <View style={{ marginBottom: 21 }}>
                        <Text style={[styles.title7, { color: '#ffffff' }]}>{translate('Estimation')}</Text>
                        <Text style={[styles.body2, { color: colors.Text03 }]}>
                            {translate('Select an estimation for your task')}
                        </Text>
                    </View>
                    <View style={localStyles.pointSection}>
                        {ESTIMATION_OPTIONS.map(item => {
                            const isSelected = selectedEstimation === item

                            return (
                                <View key={item}>
                                    <EstimationModalItem
                                        projectId={projectId}
                                        item={item}
                                        isSelected={isSelected}
                                        onPress={e => onPress(e, item)}
                                    />
                                </View>
                            )
                        })}
                    </View>
                </View>

                {estimationType === ESTIMATION_TYPE_TIME && (
                    <CustomEstimationItem
                        estimation={selectedEstimation}
                        openCustomForm={showCustomEstimationForm}
                        isSelected={!ESTIMATION_OPTIONS.includes(selectedEstimation)}
                    />
                )}

                {showBackButton && <EstimationModalBackButton closePopover={closePopover} />}

                <CloseButton
                    close={e => {
                        if (e) {
                            e.preventDefault()
                            e.stopPropagation()
                        }
                        closePopover()
                    }}
                />
            </CustomScrollView>
        </View>
    )
}
export default EstimationModal

const localStyles = StyleSheet.create({
    parent: {
        shadowColor: 'rgba(78, 93, 120, 0.56)',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 1,
        shadowRadius: 16,
        elevation: 3,
        borderRadius: 4,
        backgroundColor: colors.Secondary400,
    },
    container: {
        paddingTop: 16,
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 8,
    },
    pointSection: {
        flex: 1,
        justifyContent: 'space-around',
    },
})
