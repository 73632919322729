import React from 'react'
import { useSelector } from 'react-redux'

import { ALL_PROJECTS_INDEX } from '../SettingsView/ProjectsSettings/ProjectHelper'
import TasksMultiToggleSwitchSelectedProject from './TasksMultiToggleSwitchSelectedProject'
import TasksMultiToggleSwitchAllProjects from './TasksMultiToggleSwitchAllProjects'

export default function TasksMultiToggleSwitch() {
    const selectedProjectIndex = useSelector(state => state.selectedProjectIndex)

    return selectedProjectIndex > ALL_PROJECTS_INDEX ? (
        <TasksMultiToggleSwitchSelectedProject />
    ) : (
        <TasksMultiToggleSwitchAllProjects />
    )
}
