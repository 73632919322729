import {
    LogOut,
    setProjectTypeSectionIndex,
    setSelectedNavItem,
    setSelectedSidebarTab,
    storeCurrentUser,
    switchProject,
    updateFeedActiveTab,
} from '../../redux/actions'
import store from '../../redux/store'
import ProjectHelper, { ALL_PROJECTS_INDEX } from './ProjectsSettings/ProjectHelper'
import URLsSettings, {
    URL_CUSTOMIZATIONS,
    URL_SETTINGS_INVITATIONS,
    URL_SETTINGS_PREMIUM,
    URL_SETTINGS_PROJECTS,
    URL_SETTINGS_SHORTCUTS,
    URL_SETTINGS_STATISTICS,
    URL_SETTINGS_PROFILE,
} from '../../URLSystem/Settings/URLsSettings'
import URLSystem, {
    URL_FEEDS_FOLLOWED,
    URL_FEEDS_NOT_FOLLOWED,
    URL_PROJECT_FEEDS_FOLLOWED,
    URL_PROJECT_FEEDS_NOT_FOLLOWED,
} from '../../URLSystem/URLSystem'
import TasksHelper from '../TaskListView/Utils/TasksHelper'
import { DV_TAB_ROOT_UPDATES } from '../../utils/TabNavigationConstants'
import { PROJECT_TYPE_ACTIVE, PROJECT_TYPE_GUIDE } from './ProjectsSettings/ProjectsSettings'
import { ALL_TAB, FOLLOWED_TAB } from '../Feeds/Utils/FeedsConstants'
import { deleteCache } from '../../utils/Observers'

class SettingsHelper {
    /**
     * @param navigation
     * @param tab       [User, Projects,, Template projects Archived projects]
     * @param type      [PROJECT_TYPE_ACTIVE,PROJECT_TYPE_GUIDE, PROJECT_TYPE_ARCHIVED]
     */
    static processURLSettingsTab = (navigation, tab, type) => {
        store.dispatch(setSelectedNavItem(tab))
        switch (tab) {
            case URL_CUSTOMIZATIONS:
                URLsSettings.replace(URL_CUSTOMIZATIONS)
                break
            case URL_SETTINGS_PROFILE:
                URLsSettings.replace(URL_SETTINGS_PROFILE)
                break
            case URL_SETTINGS_PROJECTS:
                const sectionIndex = type === PROJECT_TYPE_ACTIVE ? 0 : PROJECT_TYPE_GUIDE ? 1 : 2
                store.dispatch(setProjectTypeSectionIndex(sectionIndex))
                URLsSettings.replace(URL_SETTINGS_PROJECTS)
                break
            case URL_SETTINGS_INVITATIONS:
                URLsSettings.replace(URL_SETTINGS_INVITATIONS)
                break
            case URL_SETTINGS_STATISTICS:
                URLsSettings.replace(URL_SETTINGS_STATISTICS)
                break
            case URL_SETTINGS_SHORTCUTS:
                URLsSettings.replace(URL_SETTINGS_SHORTCUTS)
                break
            case URL_SETTINGS_PREMIUM:
                URLsSettings.replace(URL_SETTINGS_PREMIUM)
                break
        }
        navigation.navigate('SettingsView')
    }

    static processURLFeeds = (navigation, constant, projectId, userId) => {
        const user = TasksHelper.getUserInProject(projectId, userId)
        const currentUser = user ? user : store.getState().loggedUser
        const projectIndex = ProjectHelper.getProjectIndexById(projectId)
        const feedActiveTab = getFollowedStateByURLConstant(constant, projectIndex === ALL_PROJECTS_INDEX)

        if (!store.getState().selectedSidebarTab) {
            navigation.navigate('Root')
        }

        store.dispatch([
            switchProject(projectIndex),
            storeCurrentUser(currentUser),
            setSelectedSidebarTab(DV_TAB_ROOT_UPDATES),
            updateFeedActiveTab(feedActiveTab),
        ])

        const data = projectId ? { projectId } : {}

        URLSystem.replace(constant, data, projectId, userId)
        // navigation.navigate('Root')
    }

    static processURLProjectFeeds = (navigation, projectId) => {
        const { loggedUser } = store.getState()
        const projectIndex = ProjectHelper.getProjectIndexById(projectId)

        if (projectIndex !== ALL_PROJECTS_INDEX) {
            URLSystem.replace(URL_PROJECT_FEEDS_FOLLOWED)
            store.dispatch([
                switchProject(projectIndex),
                storeCurrentUser(loggedUser),
                setSelectedSidebarTab(DV_TAB_ROOT_UPDATES),
            ])
        } else {
            URLSystem.replace(URL_FEEDS_FOLLOWED)
            store.dispatch([
                switchProject(ALL_PROJECTS_INDEX),
                storeCurrentUser(loggedUser),
                setSelectedSidebarTab(DV_TAB_ROOT_UPDATES),
            ])
        }
        navigation.navigate('Root')
    }

    static processURLPrivateResource = navigation => {
        navigation.navigate('PrivateResource')
    }

    static onLogOut(redirectToRoot = true) {
        //const cookie = JSON.parse(localStorage.getItem('alldone_cookie')) || {}
        //cookie.loggedIn = false
        //localStorage.setItem('alldone_cookie', JSON.stringify(cookie))
        store.dispatch(LogOut())
        deleteCache()
        if (redirectToRoot) {
            document.location = window.location.origin
        }
    }
}

const getFollowedStateByURLConstant = (constant, isAllProjects = false) => {
    switch (constant) {
        case isAllProjects ? URL_FEEDS_FOLLOWED : URL_PROJECT_FEEDS_FOLLOWED:
            return FOLLOWED_TAB
        case isAllProjects ? URL_FEEDS_NOT_FOLLOWED : URL_PROJECT_FEEDS_NOT_FOLLOWED:
            return ALL_TAB
        default:
            return FOLLOWED_TAB
    }
}

export default SettingsHelper
