import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

import styles from '../../../styles/global'
import { translate } from '../../../../i18n/TranslationService'

export default function Header() {
    return (
        <View style={localStyles.headingContainer}>
            <Text style={[styles.title7, { color: '#ffffff' }]}>{translate('Comment')}</Text>
        </View>
    )
}

const localStyles = StyleSheet.create({
    headingContainer: {
        flexDirection: 'row',
        marginBottom: 20,
    },
})
