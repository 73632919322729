import React, { useEffect, useRef, useState } from 'react'
import CloseButton from '../../../FollowUp/CloseButton'
import { applyPopoverWidth, MODAL_MAX_HEIGHT_GAP } from '../../../../utils/HelperFunctions'
import useWindowSize from '../../../../utils/useWindowSize'
import { StyleSheet, Text, TextInput, View } from 'react-native'
import styles, { colors } from '../../../styles/global'
import { translate } from '../../../../i18n/TranslationService'
import CustomScrollView from '../../../UIControls/CustomScrollView'
import Button from '../../../UIControls/Button'
import Hotkeys from 'react-hot-keys'

export default function CustomEstimationModal({ setCustomEstimation, initialEstimation = null }) {
    const [width, height] = useWindowSize()
    const [days, setDays] = useState(null)
    const [hours, setHours] = useState(null)
    const [minutes, setMinutes] = useState(null)
    const input1Ref = useRef()

    useEffect(() => {
        input1Ref?.current?.focus()

        if (initialEstimation != null && initialEstimation > 0) {
            let iDays = 0
            let iHours = 0
            let iMinutes = 0

            if (initialEstimation < 60) {
                iMinutes = initialEstimation
            } else {
                iHours = Math.floor(initialEstimation / 60)
                iMinutes = initialEstimation % 60

                if (iHours > 8) {
                    iDays = Math.floor(iHours / 8)
                    iHours = iHours % 8
                }
            }

            setDays(iDays)
            setHours(iHours)
            setMinutes(iMinutes)
        }
    }, [])

    const setEstimation = (e, escape = false) => {
        e?.preventDefault()
        e?.stopPropagation()

        const total = parseInt(days || 0) * 8 * 60 + parseInt(hours || 0) * 60 + parseInt(minutes || 0)
        setCustomEstimation(total, escape)
    }

    const checkField = (number, maxLength = 5) => {
        return !isNaN(number) && number.length <= maxLength
    }

    const onChangeDays = text => {
        checkField(text) && setDays(text)
    }

    const onChangeHours = text => {
        checkField(text) && setHours(text)
    }

    const onChangeMinutes = text => {
        checkField(text) && setMinutes(text)
    }

    return (
        <View style={[localStyles.parent, applyPopoverWidth(), { maxHeight: height - MODAL_MAX_HEIGHT_GAP }]}>
            <CustomScrollView showsVerticalScrollIndicator={false}>
                <View style={localStyles.container}>
                    <View style={{ marginBottom: 21 }}>
                        <Text style={[styles.title7, { color: '#ffffff' }]}>{translate('Custom estimation')}</Text>
                        <Text style={[styles.body2, { color: colors.Text03 }]}>
                            {translate('Enter the number of days, hours, and/or minutes for the estimation')}
                        </Text>
                    </View>

                    <View style={{ flex: 1 }}>
                        <Text style={localStyles.roleLabel}>{translate('Days')}</Text>
                        <TextInput
                            ref={input1Ref}
                            style={localStyles.input}
                            placeholderTextColor={colors.Text03}
                            placeholder={translate('days')}
                            keyboardType={'numeric'}
                            value={days}
                            onChangeText={onChangeDays}
                        />

                        <Text style={localStyles.roleLabel}>{translate('Hours')}</Text>
                        <TextInput
                            style={localStyles.input}
                            placeholderTextColor={colors.Text03}
                            placeholder={translate('hours')}
                            keyboardType={'numeric'}
                            value={hours}
                            onChangeText={onChangeHours}
                        />

                        <Text style={localStyles.roleLabel}>{translate('Minutes')}</Text>
                        <TextInput
                            style={localStyles.input}
                            placeholderTextColor={colors.Text03}
                            placeholder={translate('minutes')}
                            keyboardType={'numeric'}
                            value={minutes}
                            onChangeText={onChangeMinutes}
                        />
                    </View>
                </View>

                <View style={localStyles.buttonContainer}>
                    <Hotkeys keyName={'enter'} onKeyDown={(sht, e) => setEstimation(e)} filter={e => true}>
                        <Button
                            title={translate('Estimate')}
                            type={'primary'}
                            onPress={setEstimation}
                            accessible={false}
                            shortcutText={'Enter'}
                            disabled={false}
                        />
                    </Hotkeys>
                </View>

                <CloseButton close={e => setEstimation(e, true)} />
            </CustomScrollView>
        </View>
    )
}

const localStyles = StyleSheet.create({
    parent: {
        shadowColor: 'rgba(78, 93, 120, 0.56)',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 1,
        shadowRadius: 16,
        elevation: 3,
        borderRadius: 4,
        backgroundColor: colors.Secondary400,
    },
    container: {
        paddingTop: 16,
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 8,
    },
    buttonContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 8,
        paddingHorizontal: 16,
        borderTopColor: colors.funnyWhite,
        borderTopWidth: 1,
    },
    input: {
        ...styles.body1,
        flex: 1,
        height: 40,
        color: '#ffffff',
        borderWidth: 1,
        borderColor: colors.Grey400,
        borderRadius: 4,
        paddingVertical: 8,
        paddingHorizontal: 16,
        marginBottom: 8,
    },
    roleLabel: {
        ...styles.subtitle2,
        color: colors.Text02,
        marginTop: 4,
        marginBottom: 4,
    },
})
