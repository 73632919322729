import {
    setSelectedNavItem,
    setSelectedSidebarTab,
    setSelectedTypeOfProject,
    storeCurrentUser,
    switchProject,
} from '../../redux/actions'
import store from '../../redux/store'
import URLsAdminPanel, {
    URL_ADMIN_PANEL_ASSISTANTS,
    URL_ADMIN_PANEL_USER,
} from '../../URLSystem/AdminPanel/URLsAdminPanel'
import { DV_TAB_ROOT_TASKS } from '../../utils/TabNavigationConstants'
import { ALL_PROJECTS_INDEX } from '../SettingsView/ProjectsSettings/ProjectHelper'
import { PROJECT_TYPE_ACTIVE } from '../SettingsView/ProjectsSettings/ProjectsSettings'

export const processURLAdminPanelTab = (navigation, tab, type) => {
    const { loggedUser, administratorUser } = store.getState()
    if (loggedUser.uid === administratorUser.uid) {
        store.dispatch(setSelectedNavItem(tab))
        switch (tab) {
            case URL_ADMIN_PANEL_USER:
                URLsAdminPanel.replace(URL_ADMIN_PANEL_USER)
                break
            case URL_ADMIN_PANEL_ASSISTANTS:
                URLsAdminPanel.replace(URL_ADMIN_PANEL_ASSISTANTS)
                break
        }
        navigation.navigate('AdminPanelView')
    } else {
        store.dispatch([
            switchProject(ALL_PROJECTS_INDEX),
            setSelectedSidebarTab(DV_TAB_ROOT_TASKS),
            storeCurrentUser(loggedUser),
            setSelectedTypeOfProject(PROJECT_TYPE_ACTIVE),
        ])
        navigation.navigate('Root')
    }
}
