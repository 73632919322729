import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Animated, StyleSheet, View } from 'react-native'
import Popover from 'react-tiny-popover'
import Swipeable from 'react-native-gesture-handler/Swipeable'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import v4 from 'uuid/v4'

import styles, { colors } from '../styles/global'
import store from '../../redux/store'
import Backend from '../../utils/BackendBridge'
import NavigationService from '../../utils/NavigationService'
import { setAssignee, setSelectedNavItem, setSwipeDueDatePopupData, showSwipeDueDatePopup } from '../../redux/actions'
import SocialText from '../UIControls/SocialText'
import MyPlatform from '../MyPlatform'
import TasksHelper, { DONE_STEP, OPEN_STEP, TASK_ASSIGNEE_ASSISTANT_TYPE } from './Utils/TasksHelper'
import { chronoKeysOrder, dismissAllPopups, popoverToCenter } from '../../utils/HelperFunctions'
import SharedHelper from '../../utils/SharedHelper'
import { RECORD_SCREEN_MODAL_ID, RECORD_VIDEO_MODAL_ID } from '../Feeds/CommentsTextInput/textInputHelper'
import { MENTION_MODAL_ID } from '../ModalsManager/modalsManager'
import { WORKSTREAM_ID_PREFIX } from '../Workstreams/WorkstreamHelper'
import { getUserWorkflow } from '../ContactsView/Utils/ContactsHelper'
import CalendarTag from '../Tags/CalendarTag'
import { checkIsLimitedByXp } from '../Premium/PremiumHelper'
import GmailTag from '../Tags/GmailTag'
import TaskItemTagsContainer from './TaskItemTagsContainer'
import SwipeAreasContainer from './SwipeAreasContainer'
import VerticalEllipsis from './VerticalEllipsis'
import TaskFlowModal from './TaskFlowModal'
import CheckBoxContainer from './CheckBoxContainer'
import ShortcutsArea from './ShortcutsArea'
import SixDotsContainer from './SixDotsContainer'
import ProjectHelper from '../SettingsView/ProjectsSettings/ProjectHelper'
import { DV_TAB_TASK_CHAT } from '../../utils/TabNavigationConstants'
import { objectIsLockedForUser } from '../Guides/guidesHelper'
import { moveTasksFromDone, moveTasksFromOpen, setTaskStatus } from '../../utils/backends/Tasks/tasksFirestore'

function TaskPresentation(
    {
        task,
        projectId,
        isObservedTask,
        toggleModal,
        toggleSubTaskList,
        subtaskList,
        isSuggested,
        isActiveOrganizeMode,
        checkOnDrag,
        inParentGoal,
    },
    ref
) {
    const dispatch = useDispatch()
    const route = useSelector(state => state.route)
    const inFocusTaskId = useSelector(state => state.loggedUser.inFocusTaskId)
    const loggedUserId = useSelector(state => state.loggedUser.uid)
    const unlockedKeysByGuides = useSelector(state => state.loggedUser.unlockedKeysByGuides)
    const isAnonymous = useSelector(state => state.loggedUser.isAnonymous)
    const userProjectIds = useSelector(state => state.loggedUser.projectIds, shallowEqual)
    const currentUserId = useSelector(state => state.currentUser.uid)
    const smallScreenNavigation = useSelector(state => state.smallScreenNavigation)
    const activeEditMode = useSelector(state => state.activeEditMode)
    const [done, setDone] = useState(task.done)
    const [forceTagsMobile, setForceTagsMobile] = useState(false)
    const [taskTagsWidth, setTaskTagsWidth] = useState(0)
    const [visiblePopover, setVisiblePopover] = useState(false)
    const [blockOpen, setBlockOpen] = useState(false)
    const [tagsExpandedHeight, setTagsExpandedHeight] = useState(0)
    const [panColor, setPanColor] = useState(new Animated.Value(0))
    const itemSwipe = useRef()
    const taskTagsSection = useRef()
    const checkBoxIdRef = useRef(v4())

    const ownerIsWorkstream = task?.userId?.startsWith(WORKSTREAM_ID_PREFIX)

    useImperativeHandle(ref, () => ({
        onCheckboxPress: () => {
            const pending =
                task.userIds?.length > 1 &&
                (task.userIds?.[task.userIds?.length - 1] !== currentUserId || route === 'GoalDetailedView') &&
                task.done === false &&
                !task.parentId
            onCheckboxPress(pending || isSuggested || isObservedTask)
        },
    }))

    const renderLeftSwipe = (progress, dragX) => {
        if (panColor != dragX) setPanColor(dragX)
        return <View style={{ width: 150 }} />
    }

    const renderRightSwipe = (progress, dragX) => {
        return !task.done && <View style={{ width: 150 }} />
    }

    const onLeftSwipe = () => {
        itemSwipe.current.close()
        NavigationService.navigate('TaskDetailedView', {
            task: task,
            projectId: projectId,
        })
        dispatch(setSelectedNavItem(DV_TAB_TASK_CHAT))
    }

    const onRightSwipe = () => {
        itemSwipe.current.close()
        dismissAllPopups()
        store.dispatch([
            showSwipeDueDatePopup(),
            setSwipeDueDatePopupData({
                projectId: projectId,
                task: task,
                isObservedTask: isObservedTask,
            }),
        ])
    }

    useEffect(() => {
        MyPlatform.getElementWidth(taskTagsSection.current).then(taskTagsWidth => {
            setTaskTagsWidth(taskTagsWidth)
        })
    }, [task])

    const toggleCheckAction = (isLongPress = false) => {
        const { loggedUser } = store.getState()
        if (task.isSubtask) {
            setTaskStatus(
                projectId,
                task.id,
                !task.done,
                ownerIsWorkstream ? loggedUser.uid : task.userId,
                task,
                '',
                true,
                task.estimations[OPEN_STEP],
                task.estimations[OPEN_STEP]
            )
        } else {
            if (task.done) {
                moveTasksFromDone(projectId, task, OPEN_STEP)
            } else if (task.genericData || (task.isPrivate && !isLongPress) || task.calendarData || task.gmailData) {
                moveTasksFromOpen(projectId, task, DONE_STEP, null, null, task.estimations, checkBoxIdRef.current)
            } else if (task.userIds.length === 1 && !isLongPress) {
                const workflow = getUserWorkflow(projectId, ownerIsWorkstream ? loggedUser.uid : task.userId)
                const workflowStepsIds = workflow ? Object.keys(workflow).sort(chronoKeysOrder) : []
                moveTasksFromOpen(
                    projectId,
                    task,
                    workflowStepsIds[0] ? workflowStepsIds[0] : DONE_STEP,
                    null,
                    null,
                    task.estimations,
                    checkBoxIdRef.current
                )
            } else {
                const taskOwner = TasksHelper.getTaskOwner(task.userId, projectId)
                dispatch(setAssignee(ownerIsWorkstream ? loggedUser : taskOwner))
                showPopover()
            }
        }
    }

    const onCheckboxPress = (isLongPress = false) => {
        if (!checkIsLimitedByXp(projectId)) {
            const isAssistant = task.assigneeType === TASK_ASSIGNEE_ASSISTANT_TYPE
            setDone(!done)
            toggleCheckAction(isLongPress && (!isAssistant || isObservedTask))
        }
    }

    const onLayoutChange = layout => {
        let taskItemWidth = layout.nativeEvent.layout.width
        if (taskTagsWidth >= taskItemWidth && !forceTagsMobile) {
            setForceTagsMobile(true)
        } else if (taskTagsWidth < taskItemWidth && forceTagsMobile) {
            setForceTagsMobile(false)
        }
    }

    const showPopover = () => {
        setVisiblePopover(true)
    }

    const cancelPopover = () => {
        const { openModals, isQuillTagEditorOpen } = store.getState()
        if (
            !isQuillTagEditorOpen &&
            !openModals[RECORD_VIDEO_MODAL_ID] &&
            !openModals[RECORD_SCREEN_MODAL_ID] &&
            !openModals[MENTION_MODAL_ID]
        ) {
            setVisiblePopover(false)
            setDone(false)
        }
    }

    const pending =
        task.userIds?.length > 1 &&
        (task.userIds?.[task.userIds?.length - 1] !== currentUserId || route === 'GoalDetailedView') &&
        task.done === false &&
        !task.parentId

    const accessGranted = SharedHelper.checkIfUserHasAccessToProject(isAnonymous, userProjectIds, projectId, false)
    const anonymousGranted = SharedHelper.checkIfUserHasAccessToProject(isAnonymous, userProjectIds, projectId, true)

    const backColor = panColor.interpolate({
        inputRange: [-100, 0, 100],
        outputRange: [colors.UtilityYellow125, task.isSubtask ? colors.Grey200 : '#ffffff', colors.UtilityGreen125],
        extrapolate: 'clamp',
    })

    const backColorHighlight = panColor.interpolate({
        inputRange: [-100, 0, 100],
        outputRange: [colors.UtilityYellow125, task.hasStar, colors.UtilityGreen125],
        extrapolate: 'clamp',
    })

    const highlightColor = task.hasStar.toLowerCase() !== '#ffffff' ? backColorHighlight : backColor
    const showVerticalEllipsis = TasksHelper.showWrappedTaskEllipsis(
        `social_tags_${projectId}_${task.id}`,
        `social_text_${projectId}_${task.id}`
    )

    const loggedUserIsTaskOwner = loggedUserId === task.userId
    const loggedUserCanUpdateObject =
        loggedUserIsTaskOwner || !ProjectHelper.checkIfLoggedUserIsNormalUserInGuide(projectId)

    const isLocked = objectIsLockedForUser(projectId, unlockedKeysByGuides, task.lockKey, task.userId)

    return (
        <View style={isLocked && !inParentGoal && localStyles.blurry}>
            <SwipeAreasContainer leftText={'Chat'} rightText={'Reminder'} isActiveOrganizeMode={isActiveOrganizeMode} />
            <Swipeable
                ref={itemSwipe}
                rightThreshold={80}
                leftThreshold={80}
                enabled={!activeEditMode && !isActiveOrganizeMode && !isLocked && anonymousGranted}
                renderLeftActions={renderLeftSwipe}
                renderRightActions={accessGranted && renderRightSwipe}
                onSwipeableLeftWillOpen={onLeftSwipe}
                onSwipeableRightWillOpen={loggedUserCanUpdateObject && accessGranted && onRightSwipe}
                overshootLeft={false}
                overshootRight={false}
                friction={2}
                containerStyle={{ overflow: 'visible' }}
                failOffsetY={[-5, 5]}
                onSwipeableWillClose={() => {
                    setBlockOpen(true)
                }}
                onSwipeableClose={() => {
                    setBlockOpen(false)
                }}
            >
                <Popover
                    content={
                        <TaskFlowModal
                            task={task}
                            projectId={projectId}
                            isObservedTask={isObservedTask}
                            isSuggested={isSuggested}
                            pending={pending}
                            cancelPopover={cancelPopover}
                            checkBoxIdRef={checkBoxIdRef}
                            setVisiblePopover={setVisiblePopover}
                        />
                    }
                    onClickOutside={cancelPopover}
                    isOpen={visiblePopover}
                    padding={4}
                    position={['top']}
                    align={'center'}
                    contentLocation={args => popoverToCenter(args, smallScreenNavigation)}
                    disableReposition
                    //contentLocation={mobile ? null : undefined}
                >
                    <View style={localStyles.container}>
                        <View style={{ borderRadius: 4 }}>
                            <Animated.View
                                style={[
                                    inFocusTaskId === task.id && { borderColor: colors.Primary100, borderWidth: 2 },
                                    localStyles.taskRow,
                                    task.isSubtask ? subTaskStyles.taskRow : undefined,
                                    task.isSubtask ? { paddingLeft: 2 } : undefined,
                                    isActiveOrganizeMode &&
                                        (task.isSubtask
                                            ? subTaskStyles.dragModeContainer
                                            : localStyles.dragModeContainer),
                                    { backgroundColor: highlightColor },
                                ]}
                                onLayout={onLayoutChange}
                            >
                                <View
                                    pointerEvents={isActiveOrganizeMode || isLocked ? 'none' : 'auto'}
                                    style={[localStyles.checkBoxLabel, { paddingBottom: tagsExpandedHeight }]}
                                >
                                    <CheckBoxContainer
                                        task={task}
                                        isSubtask={task.isSubtask}
                                        isObservedTask={isObservedTask}
                                        isSuggested={isSuggested}
                                        isActiveOrganizeMode={isActiveOrganizeMode}
                                        checkOnDrag={checkOnDrag}
                                        highlightColor={highlightColor}
                                        accessGranted={accessGranted}
                                        pending={pending}
                                        onCheckboxPress={onCheckboxPress}
                                        checkBoxIdRef={checkBoxIdRef}
                                        done={done}
                                        loggedUserCanUpdateObject={loggedUserCanUpdateObject}
                                    />
                                    {task?.calendarData && (
                                        <CalendarTag
                                            calendarData={task.calendarData}
                                            propStyles={{ marginTop: 8, marginLeft: 12 }}
                                        />
                                    )}
                                    {task?.gmailData && (
                                        <GmailTag
                                            gmailData={task.gmailData}
                                            propStyles={{ marginTop: 8, marginLeft: 12 }}
                                        />
                                    )}
                                    <View
                                        style={[
                                            localStyles.descriptionContainer,
                                            task.isSubtask ? subTaskStyles.descriptionContainer : undefined,
                                        ]}
                                    >
                                        <SocialText
                                            elementId={`social_text_${projectId}_${task.id}`}
                                            style={[
                                                task.isSubtask ? styles.body2 : styles.body1,
                                                localStyles.descriptionText,
                                                task.isSubtask ? subTaskStyles.descriptionText : undefined,
                                                task.isSubtask && task.done ? { color: colors.Text03 } : undefined,
                                            ]}
                                            onPress={toggleModal}
                                            numberOfLines={3}
                                            wrapText={true}
                                            hasLinkBack={task.linkBack !== undefined && task.linkBack.length > 0}
                                            task={task}
                                            inTaskDetailedView={false}
                                            hasStar={task.hasStar}
                                            isSubtask={task.isSubtask}
                                            bgColor={task.hasStar ? backColorHighlight : backColor}
                                            projectId={projectId}
                                            blockOpen={blockOpen}
                                        >
                                            {task !== undefined && task.name != null && task.extendedName != null
                                                ? task.extendedName !== ''
                                                    ? task.extendedName
                                                    : task.name
                                                : ''}
                                        </SocialText>
                                    </View>
                                </View>

                                <Animated.View style={[localStyles.taskTags, { backgroundColor: highlightColor }]}>
                                    <View
                                        ref={taskTagsSection}
                                        style={[
                                            localStyles.innerTaskTags,
                                            task.isSubtask && subTaskStyles.innerTaskTags,
                                        ]}
                                        nativeID={`social_tags_${projectId}_${task.id}`}
                                    >
                                        {showVerticalEllipsis && (
                                            <VerticalEllipsis isSubtask={task.isSubtask} task={task} />
                                        )}
                                        <TaskItemTagsContainer
                                            task={task}
                                            isSubtask={task.isSubtask}
                                            projectId={projectId}
                                            isObservedTask={isObservedTask}
                                            toggleSubTaskList={toggleSubTaskList}
                                            subtaskList={subtaskList}
                                            isActiveOrganizeMode={isActiveOrganizeMode}
                                            accessGranted={accessGranted}
                                            anonymousGranted={anonymousGranted}
                                            forceTagsMobile={forceTagsMobile}
                                            setTagsExpandedHeight={setTagsExpandedHeight}
                                            isLocked={isLocked}
                                        />
                                    </View>
                                </Animated.View>
                            </Animated.View>
                        </View>
                        {isActiveOrganizeMode && <SixDotsContainer />}
                        <ShortcutsArea
                            task={task}
                            isActiveOrganizeMode={isActiveOrganizeMode}
                            accessGranted={accessGranted}
                            projectId={projectId}
                            isLocked={isLocked}
                        />
                    </View>
                </Popover>
            </Swipeable>
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        marginLeft: -16,
        marginRight: -16,
    },
    taskRow: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        paddingHorizontal: 8,
        marginHorizontal: 8,
        borderRadius: 4,
    },
    checkBoxLabel: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        flex: 1,
    },
    descriptionContainer: {
        flexGrow: 1,
        paddingLeft: 12,
        flex: 1,
    },
    descriptionText: {
        display: 'flex',
        marginTop: 5, // 8 - 3 px of line spacing
        marginBottom: 5, // 8 - 3 px of line spacing
        alignItems: 'flex-start',
        maxHeight: 90,
    },
    taskTags: {
        alignItems: 'center',
        flexDirection: 'row',
        position: 'absolute',
        right: 0,
        bottom: 3,
    },
    innerTaskTags: {
        marginTop: 5, // 8 - 3 px of line spacing
        marginBottom: 5, // 8 - 3 px of line spacing
        paddingRight: 8,
        alignItems: 'center',
        flexDirection: 'row',
    },
    dragModeContainer: {
        marginRight: 44,
    },
    blurry: {
        filter: 'blur(3px)',
        userSelect: 'none',
    },
})

const subTaskStyles = StyleSheet.create({
    taskRow: {
        backgroundColor: colors.Grey200,
        paddingHorizontal: 4,
        marginHorizontal: 16,
    },
    descriptionText: {
        marginTop: 6, // 9 - 3 px of line spacing
        marginBottom: 6, // 9 - 3 px of line spacing
        maxHeight: 90,
    },
    descriptionContainer: {
        paddingLeft: 10,
    },
    innerTaskTags: {
        marginTop: 6, // 9 - 3 px of line spacing
        marginBottom: 6, // 9 - 3 px of line spacing
    },
    dragModeContainer: {
        marginRight: 44,
    },
})

export default forwardRef(TaskPresentation)
