import React, { useEffect } from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import styles, { colors } from '../../../styles/global'
import Icon from '../../../Icon'
import { CONNECT_CALENDAR_MODAL_ID, removeModal, storeModal } from '../../../ModalsManager/modalsManager'
import Button from '../../../UIControls/Button'
import Backend from '../../../../utils/BackendBridge'
import GooleApi from '../../../../apis/google/GooleApi'
import { addCalendarEvents } from '../../../../utils/backends/firestore'
import { translate } from '../../../../i18n/TranslationService'
import { disableOtherProjects, isSomethingConnected } from '../../../../apis/google/ApiHelper'
import { useSelector } from 'react-redux'

export default function ConnectCalendarPopup({
    projectId,
    user,
    isConnected,
    isSignedIn,
    closePopover,
    setIsSignedIn,
}) {
    const loggedUserEmail = useSelector(state => state.loggedUser.email)
    const loggedUserName = useSelector(state => state.loggedUser.displayName)
    const loggedUserPhotoURL = useSelector(state => state.loggedUser.photoURL)

    const loadEvents = async () => {
        await GooleApi.listTodayEvents(30).then(({ result }) => {
            addCalendarEvents({
                events: result?.items,
                projectId,
                uid: user.uid,
                email: result.summary,
            })
        })
    }

    const onPress = () => {
        !isSomethingConnected() && GooleApi.handleSignOutClick()
        if (isSignedIn && isConnected) {
            Backend.getDb()
                .doc(`users/${user.uid}`)
                .set({ apisConnected: { [projectId]: { calendar: !isConnected } } }, { merge: true })
            closePopover()
        } else {
            GooleApi.handleAuthClick().then(() => {
                setIsSignedIn(GooleApi.checkAccessGranted())
                Backend.getDb()
                    .doc(`users/${user.uid}`)
                    .set({ apisConnected: { [projectId]: { calendar: true } } }, { merge: true })
                    .then(async () => {
                        await loadEvents()
                    })
                disableOtherProjects(projectId)
                closePopover()
            })
        }
    }

    useEffect(() => {
        storeModal(CONNECT_CALENDAR_MODAL_ID)
        return () => removeModal(CONNECT_CALENDAR_MODAL_ID)
    }, [])

    return (
        <View style={localStyles.container}>
            <View style={{ marginBottom: 20 }}>
                <Text style={[styles.title7, { color: '#ffffff' }]}>{translate('Google calendar account')}</Text>
                <Text style={[styles.body2, { color: colors.Text03 }]}>
                    {translate('Google calendar account description')}
                </Text>
            </View>

            {isConnected && isSignedIn && (
                <View style={localStyles.content}>
                    <Image source={{ uri: loggedUserPhotoURL }} style={localStyles.avatar} />
                    <View style={{ flexDirection: 'column' }}>
                        <Text style={localStyles.username}>{loggedUserName}</Text>
                        <Text style={localStyles.info}>
                            {translate(`${isConnected ? 'Connected' : 'Connect'} to Email`, {
                                email: loggedUserEmail,
                            })}
                        </Text>
                    </View>
                </View>
            )}

            <Button
                title={translate(isConnected && isSignedIn ? 'Disconnect' : 'Connect')}
                icon={isConnected && isSignedIn ? 'unlink' : 'link'}
                buttonStyle={{ alignSelf: 'center' }}
                onPress={onPress}
            />

            <View style={localStyles.closeContainer}>
                <TouchableOpacity style={localStyles.closeButton} onPress={closePopover}>
                    <Icon name="x" size={24} color={colors.Text03} />
                </TouchableOpacity>
            </View>
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        backgroundColor: colors.Secondary400,
        padding: 16,
        borderRadius: 4,
        width: 432,
        shadowColor: 'rgba(78, 93, 120, 0.56)',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 1,
        shadowRadius: 16,
        elevation: 3,
    },
    content: {
        flexDirection: 'row',
        marginBottom: 20,
    },
    username: {
        ...styles.subtitle1,
        color: '#ffffff',
    },
    info: {
        ...styles.body2,
        color: colors.Text03,
    },
    closeContainer: {
        position: 'absolute',
        top: 8,
        right: 8,
    },
    closeButton: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    avatar: {
        width: 44,
        height: 44,
        borderRadius: 100,
        marginRight: 8,
    },
})
