import React, { Component } from 'react'
import { Animated, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import styles, { colors } from '../styles/global'
import LinkTag from '../Tags/LinkTag'
import store from '../../redux/store'
import { setTaskTitleElementsWidths } from '../../redux/actions'
import MyPlatform from '../../components/MyPlatform'
import {
    ATTACHMENT_ELEMENT,
    EMAIL_ELEMENT,
    GENERIC_ELEMENT,
    HASH_ELEMENT,
    IMAGE_ELEMENT,
    KARMA_ELEMENT,
    MENTION_ELEMENT,
    parseFeedComment,
    tryToextractPeopleForMention,
    URL_ELEMENT,
    VIDEO_ELEMENT,
} from '../Feeds/Utils/HelperFunctions'
import TasksHelper from '../TaskListView/Utils/TasksHelper'
import MentionedCommentTag from '../Tags/MentionedCommentTag'
import HashTag from '../Tags/HashTag'
import MentionTag from '../Tags/MentionTag'
import EmailTag from '../Tags/EmailTag'
import FileDownloadableTag from '../Tags/FileDownloadableTag'
import KarmaTag from '../Tags/KarmaTag'
import { getKarmaData } from '../Feeds/CommentsTextInput/textInputHelper'
import ReactDOM from 'react-dom'
import MilestoneDateTag from '../GoalsView/MilestoneDateTag'
import DoneStateWrapper from '../GoalsView/DoneStateWrapper'
import { getAttachmentData, getImageData, getVideoData } from '../../functions/Utils/parseTextUtils'

export default class SocialText extends Component {
    constructor(props) {
        super(props)

        this.state = {
            visibleEllipsis: false,
            textSectionWidth: 0,
            textItemWidth: 0,
            wordList: [],
        }

        this.textSection = React.createRef()
        this.textElementsRefs = []
    }

    componentDidMount() {
        const { task } = this.props
        const wordList = parseFeedComment(this.props.children, task && task.genericData ? true : false, false)
        this.setState({ wordList })
        this.calculateTextSectionWidth()
        this.calculateTextElementsWidths()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.children !== this.props.children) {
            const { task } = this.props

            const wordList = parseFeedComment(this.props.children, task && task.genericData ? true : false, false)
            this.setState({ wordList })

            if (this.props.showEllipsis) {
                this.calculateTextSectionWidth()
            }
        }
    }

    calculateTextSectionWidth = () => {
        const textSectionWidth = ReactDOM.findDOMNode(this.textSection.current).offsetWidth

        this.setState({ textSectionWidth })
        if (this.state.textItemWidth > 0) {
            this.handleShowEllipsis()
        }
    }

    calculateTextElementsWidths = () => {
        // Be careful using prop "showResponsiveEllipsis"
        if (this.props.showResponsiveEllipsis) {
            let functors = []

            for (let el of this.textElementsRefs) {
                functors.push(MyPlatform.getElementWidth(el.ref))
            }

            Promise.all(functors).then(textElementsWidths => {
                let finalData = []
                let i = 0
                for (let el of this.textElementsRefs) {
                    if (textElementsWidths[i] !== undefined) {
                        finalData.push({
                            key: el.key,
                            width: textElementsWidths[i],
                        })
                    }
                    i++
                }

                store.dispatch(setTaskTitleElementsWidths(finalData))
            })
        }
    }

    onLayoutChange = layout => {
        let textItemWidth = layout.nativeEvent.layout.width
        this.setState({ textItemWidth })

        this.calculateTextSectionWidth()
    }

    onTextChange = _layout => {
        this.calculateTextSectionWidth()
    }

    handleShowEllipsis = () => {
        const { textSectionWidth, visibleEllipsis, textItemWidth } = this.state

        if (textSectionWidth > textItemWidth && !visibleEllipsis) {
            this.setState({ visibleEllipsis: true })
        } else if (textSectionWidth < textItemWidth && visibleEllipsis) {
            this.setState({ visibleEllipsis: false })
        }
    }

    registerTextElementRef = (key, ref) => {
        if (this.props.showResponsiveEllipsis) {
            this.textElementsRefs.push({ key: key, ref: ref })
        }
    }

    textWithEllipsis = (key, text, isTag) => {
        if (this.props.showResponsiveEllipsis && this.props.ellipsisPosition === key) {
            if (isTag) {
                return <Text style={[localStyles.text, this.props.normalStyle, this.props.textStyle]}>...</Text>
            }
            return text + '...'
        } else {
            if (isTag) {
                return null
            }
            return text
        }
    }

    shouldOnPress = event => {
        const { target } = event
        const { blockOpen } = this.props
        let shouldOnPress = true
        document.querySelectorAll('.react-tiny-popover-container').forEach(e => {
            if (e.contains(target)) {
                shouldOnPress = false
            }
        })
        if (shouldOnPress) {
            const label = target.getAttribute('aria-label')
            shouldOnPress = label == null || label === '' || label !== 'social-text-block'
        }
        return !blockOpen && shouldOnPress
    }

    render() {
        const { wordList } = this.state
        const {
            elementId,
            style,
            onPress,
            numberOfLines,
            wrapText,
            hasLinkBack,
            linkStyle,
            task,
            inTaskDetailedView,
            emailStyle,
            hashtagStyle,
            mentionStyle,
            textStyle,
            normalStyle,
            isSubtask,
            hasStar,
            bgColor,
            showEllipsis,
            projectId,
            inFeedComment,
            milestoneDate,
            milestone,
            isActiveMilestone,
            renderAsFirstElement,
        } = this.props

        let emails = 0
        let links = 0
        const marginRight = 4.3

        return (
            <Text
                style={[style, { textAlignVertical: 'center', flexDirection: 'row' }]}
                onPress={e => {
                    if (onPress && this.shouldOnPress(e)) onPress(e)
                }}
                numberOfLines={numberOfLines}
                onLayout={this.onLayoutChange}
            >
                <View
                    ref={this.textSection}
                    style={[localStyles.container, wrapText ? localStyles.wrapContent : undefined]}
                    onLayout={this.onTextChange}
                >
                    {milestone && (isActiveMilestone || milestone.done) && (
                        <DoneStateWrapper projectId={projectId} milestone={milestone} />
                    )}
                    {milestoneDate && <MilestoneDateTag date={milestoneDate} />}
                    {renderAsFirstElement && renderAsFirstElement}
                    {wordList.map((word, i) => {
                        if (word.type === GENERIC_ELEMENT && task) {
                            const { parentObjectId, parentType, assistantId } = task.genericData
                            return (
                                <MentionedCommentTag
                                    key={i}
                                    projectId={projectId}
                                    parentObjectId={parentObjectId}
                                    parentType={parentType}
                                    inDetailView={inTaskDetailedView}
                                    linkForNoteTopic={wordList.find(e => e.link)?.link}
                                    assistantId={assistantId}
                                />
                            )
                        } else if (word.type === KARMA_ELEMENT) {
                            const { userId } = getKarmaData(word.text)
                            return (
                                <KarmaTag
                                    key={i}
                                    userId={userId}
                                    style={[{ marginRight }, wrapText && localStyles.wrappedText]}
                                />
                            )
                        } else if (word.type === ATTACHMENT_ELEMENT) {
                            const { uri, attachmentText } = getAttachmentData(word.text)
                            return (
                                <FileDownloadableTag
                                    projectId={projectId}
                                    key={i}
                                    text={attachmentText}
                                    uri={uri}
                                    style={[{ marginRight }, wrapText && localStyles.wrappedText]}
                                    inDetaliedView={inTaskDetailedView}
                                />
                            )
                        } else if (word.type === IMAGE_ELEMENT) {
                            const { uri, imageText } = getImageData(word.text)
                            return (
                                <FileDownloadableTag
                                    projectId={projectId}
                                    key={i}
                                    text={imageText}
                                    uri={uri}
                                    style={[{ marginRight }, wrapText && localStyles.wrappedText]}
                                    inDetaliedView={inTaskDetailedView}
                                />
                            )
                        } else if (word.type === VIDEO_ELEMENT) {
                            const { uri, videoText } = getVideoData(word.text)
                            return (
                                <FileDownloadableTag
                                    projectId={projectId}
                                    key={i}
                                    text={videoText}
                                    uri={uri}
                                    style={[{ marginRight }, wrapText && localStyles.wrappedText]}
                                    inDetaliedView={inTaskDetailedView}
                                />
                            )
                        } else if (word.type === URL_ELEMENT) {
                            const { link } = word
                            const people = tryToextractPeopleForMention(projectId, link)
                            if (people) {
                                const { peopleName } = people
                                return (
                                    <TouchableOpacity
                                        ref={ref => this.registerTextElementRef(`${i}`, ref)}
                                        key={i}
                                        style={{ flexDirection: 'row', marginRight }}
                                    >
                                        <MentionTag
                                            style={mentionStyle}
                                            tagStyle={wrapText && localStyles.wrappedText}
                                            text={peopleName}
                                            inTaskDV={inTaskDetailedView}
                                            user={people}
                                            projectId={projectId}
                                            useCommentTagStyle={inFeedComment}
                                        />
                                        {this.textWithEllipsis(`${i}`, '', true)}
                                    </TouchableOpacity>
                                )
                            }
                            links++
                            return (
                                <View
                                    ref={ref => this.registerTextElementRef(`${i}`, ref)}
                                    key={`${i}_${hasLinkBack ? task.linkBack : word.link}`}
                                    style={[localStyles.centeredRow, { marginRight }]}
                                >
                                    {hasLinkBack ? (
                                        <LinkTag
                                            link={`${window.location.origin}${task.linkBack}`}
                                            text={'Link ' + links}
                                            style={linkStyle}
                                            tagStyle={wrapText && localStyles.wrappedText}
                                            inTaskDV={inTaskDetailedView}
                                            useCommentTagStyle={inFeedComment}
                                            expandFullTitle={!!task.genericData}
                                            taskId={task.id}
                                            projectId={projectId}
                                        />
                                    ) : (
                                        <LinkTag
                                            link={word.link}
                                            text={'Link ' + links}
                                            style={linkStyle}
                                            tagStyle={wrapText && localStyles.wrappedText}
                                            inTaskDV={inTaskDetailedView}
                                            useCommentTagStyle={inFeedComment}
                                            expandFullTitle={task && !!task.genericData}
                                            taskId={task?.id}
                                            projectId={projectId}
                                        />
                                    )}

                                    {this.textWithEllipsis(`${i}`, '', true)}
                                </View>
                            )
                        } else if (word.type === EMAIL_ELEMENT) {
                            emails++
                            return (
                                <TouchableOpacity
                                    ref={ref => this.registerTextElementRef(`${i}`, ref)}
                                    key={`${i}_${word.email}`}
                                    style={[localStyles.centeredRow, { marginRight }]}
                                >
                                    <EmailTag
                                        style={emailStyle}
                                        tagStyle={wrapText && localStyles.wrappedText}
                                        address={word.email}
                                        text={'Mail ' + emails}
                                        inTaskDV={inTaskDetailedView}
                                        useCommentTagStyle={inFeedComment}
                                    />
                                    {this.textWithEllipsis(`${i}`, '', true)}
                                </TouchableOpacity>
                            )
                        } else if (word.type === HASH_ELEMENT) {
                            return (
                                <TouchableOpacity
                                    ref={ref => this.registerTextElementRef(`${i}`, ref)}
                                    key={`${i}_${word.text.trim()}`}
                                    style={{ flexDirection: 'row', marginRight }}
                                >
                                    <HashTag
                                        projectId={projectId}
                                        style={hashtagStyle}
                                        tagStyle={wrapText && localStyles.wrappedText}
                                        text={word.text.trim()}
                                        inTaskDV={inTaskDetailedView}
                                        useCommentTagStyle={inFeedComment}
                                    />
                                    {this.textWithEllipsis(`${i}`, '', true)}
                                </TouchableOpacity>
                            )
                        } else if (word.type === MENTION_ELEMENT) {
                            let { mention, user } = TasksHelper.getDataFromMention(word.text, projectId)
                            return (
                                <TouchableOpacity
                                    ref={ref => this.registerTextElementRef(`${i}`, ref)}
                                    key={i}
                                    style={{ flexDirection: 'row', marginRight }}
                                >
                                    <MentionTag
                                        style={mentionStyle}
                                        tagStyle={wrapText && localStyles.wrappedText}
                                        text={mention}
                                        inTaskDV={inTaskDetailedView}
                                        user={user}
                                        projectId={projectId}
                                        useCommentTagStyle={inFeedComment}
                                    />
                                    {this.textWithEllipsis(`${i}`, '', true)}
                                </TouchableOpacity>
                            )
                        } else if (word.text.length > 0) {
                            const wordList = word.text.trim().split(' ')

                            return wordList.map((word, j) => (
                                <Text
                                    ref={ref => this.registerTextElementRef(`${i}${j}`, ref)}
                                    key={`${i}${j}`}
                                    style={[
                                        localStyles.text,
                                        textStyle,
                                        normalStyle,
                                        wrapText && localStyles.wrappedText,
                                        { marginRight },
                                    ]}
                                    numberOfLines={numberOfLines}
                                >
                                    {this.textWithEllipsis(`${i}${j}`, word)}
                                    {j === wordList.length - 1 ? '' : ' '}
                                </Text>
                            ))
                        }
                    })}

                    {elementId && <View style={{ visibility: 'hidden' }} nativeID={elementId} />}
                </View>

                {showEllipsis && this.state.visibleEllipsis && (
                    <Animated.View
                        style={[
                            styles.body1,
                            localStyles.ellipsis,
                            textStyle,
                            normalStyle,
                            { justifyContent: 'center' },
                            isSubtask ? { backgroundColor: colors.Grey200 } : undefined,
                            hasStar ? { backgroundColor: hasStar } : undefined,
                            bgColor ? { backgroundColor: bgColor } : undefined,
                        ]}
                    >
                        <Text>...</Text>
                    </Animated.View>
                )}
            </Text>
        )
    }
}

export const getCustomStyle = (inTaskDV, user, inFeedComment) => {
    return inTaskDV
        ? [{ height: 32, paddingRight: 12, paddingLeft: 7 }, user && { paddingLeft: 4 }]
        : inFeedComment
        ? { minHeight: 20, height: 20, paddingRight: 6 }
        : { height: 24, paddingRight: 8 }
}

const localStyles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    wrapContent: {
        flex: 1,
        flexWrap: 'wrap',
    },
    links: {
        color: colors.Primary100,
        backgroundColor: '#D6EBFF',
        paddingRight: 8,
        paddingLeft: 2,
        borderRadius: 12,
        alignItems: 'center',
        justifyContent: 'center',
    },
    centeredRow: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    centeredFlex: {
        display: 'flex',
        alignItems: 'center',
    },
    ellipsis: {
        position: 'absolute',
        right: 0,
        backgroundColor: '#ffffff',
    },
    text: {
        whiteSpace: 'pre-wrap',
    },
    wrappedText: {
        marginTop: 3,
        marginBottom: 3,
    },
})
