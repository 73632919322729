import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import OpenTasksViewSelectedProject from './OpenTasksView/OpenTasksViewSelectedProject'
import { ALL_PROJECTS_INDEX } from '../SettingsView/ProjectsSettings/ProjectHelper'
import OpenTasksViewAllProjectsContainer from './OpenTasksView/OpenTasksViewAllProjectsContainer'

export default function OpenTasksSection() {
    const selectedProjectIndex = useSelector(state => state.selectedProjectIndex)

    const inAllProjects = selectedProjectIndex <= ALL_PROJECTS_INDEX

    return (
        <View style={{ flex: 1 }}>
            {inAllProjects ? <OpenTasksViewAllProjectsContainer /> : <OpenTasksViewSelectedProject />}
        </View>
    )
}
