import React, { useEffect } from 'react'
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import {
    hideFloatPopup,
    hideGlobalSearchPopup,
    setGlobalSearchResults,
    setSelectedSidebarTab,
    setSelectedTypeOfProject,
    setTaskViewToggleIndex,
    setTaskViewToggleSection,
    storeCurrentUser,
    switchProject,
    switchShortcutProject,
    updateFeedActiveTab,
} from '../../../redux/actions'
import styles, { em2px } from '../../styles/global'
import { PROJECT_TYPE_ACTIVE, PROJECT_TYPE_GUIDE } from '../../SettingsView/ProjectsSettings/ProjectsSettings'
import { ALL_PROJECTS_INDEX } from '../../SettingsView/ProjectsSettings/ProjectHelper'
import NavigationService from '../../../utils/NavigationService'
import { FOLLOWED_TAB } from '../../Feeds/Utils/FeedsConstants'
import { DV_TAB_ROOT_TASKS, ROOT_ROUTES } from '../../../utils/TabNavigationConstants'
import Shortcut from '../../UIControls/Shortcut'
import { exitsOpenModals } from '../../ModalsManager/modalsManager'
import { getTheme } from '../../../Themes/Themes'
import { Themes } from '../Themes'
import { translate } from '../../../i18n/TranslationService'
import useCollapsibleSidebar from '../Collapsible/UseCollapsibleSidebar'
import useOnHover from '../../../hooks/UseOnHover'
import store from '../../../redux/store'
import AmountBadgeContainer from './AmountBadgeContainer'

export default function AllProjectsButton() {
    const dispatch = useDispatch()
    const showShortcuts = useSelector(state => state.showShortcuts)
    const showFloatPopup = useSelector(state => state.showFloatPopup)
    const themeName = useSelector(state => state.loggedUser.themeName)
    const loggedUserPhotoURL = useSelector(state => state.loggedUser.photoURL)
    const selectedProjectIndex = useSelector(state => state.selectedProjectIndex)
    const selectedTypeOfProject = useSelector(state => state.selectedTypeOfProject)
    const shortcutSelectedProjectIndex = useSelector(state => state.shortcutSelectedProjectIndex)
    const route = useSelector(state => state.route)

    const { expanded } = useCollapsibleSidebar()

    const highlight =
        selectedProjectIndex === ALL_PROJECTS_INDEX &&
        (selectedTypeOfProject === PROJECT_TYPE_ACTIVE || selectedTypeOfProject === PROJECT_TYPE_GUIDE)

    const showShortcut = showShortcuts && showFloatPopup === 0 && !exitsOpenModals()
    const { hover, onHover, offHover } = useOnHover(highlight, highlight)

    const theme = getTheme(Themes, themeName, 'CustomSideMenu.AllProjects')

    useEffect(() => {
        if (shortcutSelectedProjectIndex === ALL_PROJECTS_INDEX) onPress()
    }, [shortcutSelectedProjectIndex])

    const onPress = e => {
        e?.preventDefault()

        if (!ROOT_ROUTES.includes(route)) NavigationService.navigate('Root')
        const { loggedUser } = store.getState()

        dispatch([
            hideFloatPopup(),
            setTaskViewToggleIndex(0),
            setSelectedSidebarTab(DV_TAB_ROOT_TASKS), // Comment this to NOT go to Tasks when change project in sidebar
            setTaskViewToggleSection('Open'),
            switchProject(ALL_PROJECTS_INDEX),
            updateFeedActiveTab(FOLLOWED_TAB),
            storeCurrentUser(loggedUser),
            setSelectedTypeOfProject(PROJECT_TYPE_ACTIVE),
            switchShortcutProject(null),
            hideGlobalSearchPopup(),
            setGlobalSearchResults(null),
        ])
    }

    return (
        <TouchableOpacity
            style={[
                ...(highlight
                    ? [localStyles.containerActive, theme.containerActive]
                    : [localStyles.containerInactive, theme.containerInactive]),
                !expanded && localStyles.containerCollapsed,
                !highlight && hover && theme.containerActive,
            ]}
            onPress={onPress}
            onMouseEnter={onHover}
            onMouseLeave={offHover}
        >
            {showShortcut && (
                <View style={localStyles.shortcut}>
                    <Shortcut text={'Shift+0'} />
                </View>
            )}

            <View style={localStyles.innerContainer}>
                <Image source={{ uri: loggedUserPhotoURL }} style={localStyles.userImage} />
                {expanded && (
                    <Text
                        style={
                            selectedProjectIndex > ALL_PROJECTS_INDEX
                                ? [localStyles.titleInactive, theme.titleInactive]
                                : [localStyles.title, theme.title]
                        }
                    >
                        {translate('All projects')}
                    </Text>
                )}
            </View>

            {selectedProjectIndex > ALL_PROJECTS_INDEX && <AmountBadgeContainer />}
        </TouchableOpacity>
    )
}

const localStyles = StyleSheet.create({
    containerActive: {
        paddingLeft: 24,
        alignItems: 'center',
        flexDirection: 'row',
        height: 56,
        justifyContent: 'space-between',
    },
    containerInactive: {
        paddingLeft: 24,
        opacity: 0.8,
        alignItems: 'center',
        flexDirection: 'row',
        height: 56,
        justifyContent: 'space-between',
    },

    containerCollapsed: {
        paddingLeft: 17,
    },
    innerContainer: {
        alignItems: 'center',
        flexDirection: 'row',
    },
    userImage: {
        height: 22,
        width: 22,
        borderRadius: 100,
        marginRight: 10,
    },
    shortcut: {
        position: 'absolute',
        top: 2,
        right: 2,
        zIndex: 10,
    },
    title: {
        ...styles.subtitle1,
    },
    titleInactive: {
        fontFamily: 'Roboto-Regular',
        fontSize: 16,
        lineHeight: 24,
        letterSpacing: em2px(0.02),
    },
})
