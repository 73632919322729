import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DragTaskModal from './DragTaskModal'
import DragGoalModal from './DragGoalModal'
import { setActiveDragGoalMode, setActiveDragSkillModeId } from '../../../redux/actions'

export default function DragModalsContainer() {
    const dispatch = useDispatch()
    const activeDragSkillModeId = useSelector(state => state.activeDragSkillModeId)
    const activeDragGoalMode = useSelector(state => state.activeDragGoalMode)
    const activeDragTaskModeInDate = useSelector(state => state.activeDragTaskModeInDate)

    const closeGoalDragMode = () => {
        dispatch(setActiveDragGoalMode(false))
    }

    const closeSkillDragMode = () => {
        dispatch(setActiveDragSkillModeId(false))
    }

    return (
        <>
            {activeDragTaskModeInDate && <DragTaskModal projectId={activeDragTaskModeInDate.projectId} />}
            {activeDragGoalMode && <DragGoalModal closeDragMode={closeGoalDragMode} />}
            {activeDragSkillModeId && <DragGoalModal closeDragMode={closeSkillDragMode} />}
        </>
    )
}
