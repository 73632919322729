import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import styles, { colors, windowTagStyle } from '../styles/global'
import Icon from '../Icon'
import moment from 'moment'
import { getTimeFormat } from '../UIComponents/FloatModals/DateFormatPickerModal'

const CalendarTag = ({ calendarData, propStyles }) => {
    const showStartTime = () => {
        if (calendarData.start.hasOwnProperty('dateTime')) {
            return moment(calendarData.start.dateTime).format(getTimeFormat())
        } else return 'All day'
    }
    const openLink = () => {
        return window.open(calendarData.link + `&authuser=${calendarData.email}`, '_blank')
    }

    return (
        <TouchableOpacity style={[localStyles.tag, propStyles]} onPress={openLink}>
            <View style={localStyles.icon}>
                <Icon name={'calendar'} size={16} color={colors.Text03} />
            </View>
            <Text style={[styles.subtitle2, localStyles.text, windowTagStyle()]}>{showStartTime()}</Text>
        </TouchableOpacity>
    )
}

const localStyles = StyleSheet.create({
    tag: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.Gray300,
        borderRadius: 12,
        paddingHorizontal: 8,
        marginRight: -8,
        height: 24,
    },
    text: {
        color: colors.Text03,
        marginLeft: 6,
        marginRight: 4,
    },
    icon: {
        flexDirection: 'row',
        alignSelf: 'center',
    },
})

export default CalendarTag
