import React, { useState } from 'react'
import Button from '../../../UIControls/Button'
import Popover from 'react-tiny-popover'
import { Image, StyleSheet, Text, View } from 'react-native'
import Icon from '../../../Icon'
import styles, { colors } from '../../../styles/global'
import ConnectCalendarPopup from './ConnectCalendarPopup'
import { useSelector } from 'react-redux'
import { translate } from '../../../../i18n/TranslationService'
import GooleApi from '../../../../apis/google/GooleApi'

const ConnectCalendar = ({ project, disabled }) => {
    const { uid, photoURL, displayName, email } = useSelector(state => state.loggedUser)
    const [isOpen, setIsOpen] = useState(false)
    const isConnected = useSelector(state => state.loggedUser.apisConnected?.[project.id]?.calendar)
    const [isSignedIn, setIsSignedIn] = useState(GooleApi.checkAccessGranted())

    return (
        <Popover
            content={
                <ConnectCalendarPopup
                    projectId={project.id}
                    isConnected={isConnected}
                    isSignedIn={isSignedIn}
                    user={{ uid, photoURL, displayName, email }}
                    closePopover={() => setIsOpen(false)}
                    setIsSignedIn={setIsSignedIn}
                />
            }
            onClickOutside={() => setIsOpen(false)}
            isOpen={isOpen}
            position={['right', 'bottom', 'left', 'top']}
            padding={4}
            align={'end'}
            contentLocation={false ? null : undefined}
        >
            <View style={localStyles.propertyRow}>
                <View style={{ justifyContent: 'flex-start', flexDirection: 'row', alignItems: 'center', flex: 1 }}>
                    <Icon name={'calendar'} size={24} color={colors.Text03} style={{ marginHorizontal: 8 }} />
                    <Text style={[styles.subtitle2, { color: colors.Text03 }]}>{translate('Connect calendar')}</Text>
                </View>
                <View style={{ justifyContent: 'flex-end' }}>
                    <Button
                        icon={isConnected && isSignedIn ? null : 'link'}
                        customIcon={
                            isConnected && isSignedIn ? (
                                <Image source={{ uri: photoURL }} style={localStyles.avatar} />
                            ) : null
                        }
                        title={translate(isConnected && isSignedIn ? 'Connected' : 'Connect')}
                        type={'ghost'}
                        onPress={() => setIsOpen(!isOpen)}
                        disabled={disabled}
                    />
                </View>
            </View>
        </Popover>
    )
}

const localStyles = StyleSheet.create({
    propertyRow: {
        height: 56,
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
    },
    avatar: {
        width: 24,
        height: 24,
        borderRadius: 100,
    },
})

export default ConnectCalendar
