import React, { useState, useEffect } from 'react'
import { Image, StyleSheet, View } from 'react-native'
import moment from 'moment'
import { useSelector } from 'react-redux'

import { colors } from '../styles/global'
import Icon from '../Icon'
import TaskEstimation from '../Tags/TaskEstimation'
import TaskSubTasks from '../Tags/TaskSubTasks'
import TaskSummation from '../Tags/TaskSummation'
import TaskItemTags from './TaskItemTags'
import TaskRecurrence from '../Tags/TaskRecurrence'
import TasksHelper, { OPEN_STEP, RECURRENCE_NEVER } from './Utils/TasksHelper'
import TaskCommentsWrapper from '../Tags/TaskCommentsWrapper'
import DateTagButton from '../UIControls/DateTagButton'
import BacklinksTag from '../Tags/BacklinksTag'
import PrivacyTag from '../Tags/PrivacyTag'
import { FEED_TASK_OBJECT_TYPE } from '../Feeds/Utils/FeedsConstants'
import DescriptionTag from '../Tags/DescriptionTag'
import { LINKED_OBJECT_TYPE_TASK, getDvMainTabLink } from '../../utils/LinkingHelper'
import ObjectNoteTag from '../Tags/ObjectNoteTag'
import { getEstimationRealValue } from '../../utils/EstimationHelper'
import CounterTag from '../Tags/CounterTag'
import { DV_TAB_GOAL_LINKED_TASKS } from '../../utils/TabNavigationConstants'
import { WORKSTREAM_ID_PREFIX } from '../Workstreams/WorkstreamHelper'
import Backend from '../../utils/BackendBridge'
import URLTrigger from '../../URLSystem/URLTrigger'
import NavigationService from '../../utils/NavigationService'
import ProjectHelper from '../SettingsView/ProjectsSettings/ProjectHelper'
import { setTaskDescription } from '../../utils/backends/Tasks/tasksFirestore'

export default function TaskItemTagsContainer({
    task,
    isSubtask,
    projectId,
    isObservedTask,
    toggleSubTaskList,
    subtaskList,
    isActiveOrganizeMode,
    accessGranted,
    anonymousGranted,
    forceTagsMobile,
    setTagsExpandedHeight,
    isLocked,
}) {
    const inBacklinksView = useSelector(state => state.inBacklinksView)
    const loggedUser = useSelector(state => state.loggedUser)
    const currentUser = useSelector(state => state.currentUser)
    const selectedTab = useSelector(state => state.selectedNavItem)
    const [backlinksTasksCount, setBacklinksTasksCount] = useState(0)
    const [backlinkTaskObject, setBacklinkTaskObject] = useState(null)
    const [backlinksNotesCount, setBacklinksNotesCount] = useState(0)
    const [backlinkNoteObject, setBacklinkNoteObject] = useState(null)

    const inGoalLinkedTasksView = selectedTab === DV_TAB_GOAL_LINKED_TASKS

    const ownerIsWorkstream = task.userId.startsWith(WORKSTREAM_ID_PREFIX)
    const taskOwner = ownerIsWorkstream ? loggedUser : TasksHelper.getTaskOwner(task.userId, projectId)

    const navigateToDv = () => {
        const path = getDvMainTabLink(projectId, task.id, 'tasks')
        URLTrigger.processUrl(NavigationService, path)
    }

    const getSumEstimation = subtaskList => {
        return subtaskList.reduce((sum, subTask) => {
            return sum + getEstimationRealValue(projectId, subTask.estimations[OPEN_STEP])
        }, 0)
    }

    const getEstimationsTagsInfo = taskOwnerUser => {
        const { estimations } = task
        let ownerEstimation = estimations ? estimations[OPEN_STEP] : 0
        let currentReviewerEstimation = 0
        let currentStepId
        let photoUrl

        const amountUsers = task.userIds?.length || 0

        if (amountUsers > 1 && taskOwnerUser) {
            photoUrl = taskOwnerUser.photoURL
            currentStepId = task.stepHistory[amountUsers - 1]
            currentReviewerEstimation = estimations[currentStepId]
        }

        return { ownerEstimation, currentReviewerEstimation, currentStepId, photoUrl }
    }

    const updateDescription = description => {
        setTaskDescription(projectId, task.id, description, task, task.description)
    }

    useEffect(() => {
        Backend.watchBacklinksCount(
            projectId,
            {
                type: LINKED_OBJECT_TYPE_TASK,
                idsField: 'linkedParentTasksIds',
                id: task.id,
            },
            (parentObjectType, parentsAmount, aloneParentObject) => {
                if (parentObjectType === 'tasks') {
                    setBacklinksTasksCount(parentsAmount)
                    setBacklinkTaskObject(aloneParentObject)
                } else if (parentObjectType === 'notes') {
                    setBacklinksNotesCount(parentsAmount)
                    setBacklinkNoteObject(aloneParentObject)
                }
            }
        )

        return () => {
            Backend.unwatchBacklinksCount(task.id)
        }
    }, [task.id])

    const { ownerEstimation, currentReviewerEstimation, currentStepId, photoUrl } = getEstimationsTagsInfo(taskOwner)

    const isOverdue = moment(isObservedTask ? task.dueDateByObserversIds[currentUser.uid] : task.dueDate).isBefore(
        moment(),
        'day'
    )
    const isToday = moment(isObservedTask ? task.dueDateByObserversIds[currentUser.uid] : task.dueDate).isSame(
        moment(),
        'day'
    )

    const { commentsData } = task

    const backlinksCount = backlinksTasksCount + backlinksNotesCount

    const backlinkObject =
        backlinksCount === 1 ? (backlinksTasksCount === 1 ? backlinkTaskObject : backlinkNoteObject) : null

    const inBacklog = task.dueDate === Number.MAX_SAFE_INTEGER

    const loggedUserIsTaskOwner = loggedUser.uid === task.userId
    const loggedUserCanUpdateObject =
        loggedUserIsTaskOwner || !ProjectHelper.checkIfLoggedUserIsNormalUserInGuide(projectId)

    return (
        <TaskItemTags isSubtask={isSubtask} setTagsExpandedHeight={value => setTagsExpandedHeight(value)}>
            {!!commentsData && (
                <TaskCommentsWrapper
                    commentsData={commentsData}
                    projectId={projectId}
                    objectId={task.id}
                    objectType="tasks"
                    userGettingKarmaId={task.userId}
                    disabled={isActiveOrganizeMode || isLocked}
                    objectName={task.name}
                    assistantId={task.assistantId}
                />
            )}
            {subtaskList && subtaskList.length > 0 && (
                <TaskSubTasks
                    amountOfSubTasks={subtaskList.length}
                    style={{ marginLeft: 8 }}
                    onPress={toggleSubTaskList}
                    isMobile={forceTagsMobile}
                    disabled={isLocked}
                />
            )}
            {subtaskList && subtaskList.length > 0 && (
                <TaskSummation
                    projectId={projectId}
                    estimation={getSumEstimation(subtaskList)}
                    style={{ marginLeft: 8 }}
                    isMobile={forceTagsMobile}
                    disabled={isActiveOrganizeMode || isLocked}
                />
            )}
            {currentReviewerEstimation > 0 && (
                <TaskEstimation
                    task={task}
                    projectId={projectId}
                    style={{ marginLeft: 8 }}
                    isMobile={forceTagsMobile}
                    currentEstimation={currentReviewerEstimation}
                    stepId={currentStepId}
                    disabled={isActiveOrganizeMode || isLocked || !accessGranted || !loggedUserCanUpdateObject}
                />
            )}
            {ownerEstimation > 0 && (
                <TaskEstimation
                    task={task}
                    projectId={projectId}
                    style={{ marginLeft: 8 }}
                    isMobile={forceTagsMobile}
                    currentEstimation={ownerEstimation}
                    stepId={OPEN_STEP}
                    photoUrl={photoUrl}
                    disabled={isActiveOrganizeMode || isLocked || !accessGranted || !loggedUserCanUpdateObject}
                />
            )}
            {task.timesPostponed >= 3 && (
                <CounterTag
                    icon={'coffee'}
                    style={{ marginLeft: 8 }}
                    counter={task.timesPostponed}
                    onPress={navigateToDv}
                    disabled={isLocked}
                />
            )}
            {task.timesFollowed >= 3 && (
                <CounterTag
                    icon={'calendar-up'}
                    style={{ marginLeft: 8 }}
                    counter={task.timesFollowed}
                    onPress={navigateToDv}
                    disabled={isLocked}
                />
            )}
            {task.recurrence !== RECURRENCE_NEVER && task.timesDoneInExpectedDay >= 3 && (
                <CounterTag
                    icon={'thumbs-up-checked'}
                    style={{ marginLeft: 8 }}
                    counter={task.timesDoneInExpectedDay}
                    onPress={navigateToDv}
                    disabled={isLocked}
                />
            )}
            {task.recurrence !== RECURRENCE_NEVER && task.timesDoneInExpectedDay < 3 && task.timesDone >= 3 && (
                <CounterTag
                    icon={'square-checked'}
                    style={{ marginLeft: 8 }}
                    counter={task.timesDone}
                    onPress={navigateToDv}
                    disabled={isLocked}
                />
            )}
            {task.isPrivate && (
                <PrivacyTag
                    projectId={projectId}
                    object={task}
                    objectType={FEED_TASK_OBJECT_TYPE}
                    style={{ marginLeft: 8 }}
                    isMobile={forceTagsMobile}
                    disabled={isActiveOrganizeMode || isLocked || !accessGranted || !loggedUserCanUpdateObject}
                />
            )}
            {task.recurrence !== RECURRENCE_NEVER && (
                <TaskRecurrence
                    task={task}
                    projectId={projectId}
                    style={{ marginLeft: 8 }}
                    isMobile={forceTagsMobile}
                    disabled={isActiveOrganizeMode || isLocked || !accessGranted || !loggedUserCanUpdateObject}
                />
            )}
            {task.noteId && (
                <ObjectNoteTag
                    objectId={task.id}
                    objectType="tasks"
                    projectId={projectId}
                    style={{ marginLeft: 8 }}
                    disabled={isActiveOrganizeMode || isLocked || !anonymousGranted}
                />
            )}
            {backlinksCount > 0 && (
                <BacklinksTag
                    object={task}
                    objectType={LINKED_OBJECT_TYPE_TASK}
                    projectId={projectId}
                    style={{ marginLeft: 8 }}
                    isMobile={forceTagsMobile}
                    disabled={isActiveOrganizeMode || isLocked || !anonymousGranted}
                    backlinksCount={backlinksCount}
                    backlinkObject={backlinkObject}
                />
            )}
            {task?.description?.length > 0 && (
                <DescriptionTag
                    projectId={projectId}
                    object={task}
                    style={{ marginLeft: 8 }}
                    disabled={isActiveOrganizeMode || isLocked || !anonymousGranted || !loggedUserCanUpdateObject}
                    objectType={FEED_TASK_OBJECT_TYPE}
                    updateDescription={updateDescription}
                />
            )}
            {isObservedTask && !isToday && ((!task.done && !inBacklog) || inBacklinksView) && (
                <DateTagButton
                    task={task}
                    projectId={projectId}
                    isMobile={forceTagsMobile}
                    disabled={isActiveOrganizeMode || isLocked || !accessGranted || !loggedUserCanUpdateObject}
                    style={{ marginLeft: 8 }}
                />
            )}
            {((isOverdue && !task.done && !inBacklog) || inBacklinksView) && (
                <DateTagButton
                    task={task}
                    projectId={projectId}
                    isMobile={forceTagsMobile}
                    disabled={isActiveOrganizeMode || isLocked || !accessGranted || !loggedUserCanUpdateObject}
                    style={{ marginLeft: 8 }}
                    isObservedTask={isObservedTask}
                />
            )}
            {(inBacklinksView || inGoalLinkedTasksView) && taskOwner && (
                <View style={localStyles.notesAssignee}>
                    {ownerIsWorkstream ? (
                        <Icon name="workstream" size={24} color={colors.Text03} />
                    ) : (
                        <Image style={localStyles.notesAssigneeImage} source={taskOwner?.photoURL} />
                    )}
                </View>
            )}
        </TaskItemTags>
    )
}

const localStyles = StyleSheet.create({
    notesAssignee: {
        flexDirection: 'row',
        marginLeft: 8,
        alignItems: 'center',
        justifyContent: 'center',
    },
    notesAssigneeImage: {
        width: 24,
        height: 24,
        borderRadius: 100,
    },
})
