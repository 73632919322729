import { useEffect } from 'react'
import v4 from 'uuid/v4'
import { useDispatch } from 'react-redux'

import { unwatch, watchProject } from '../utils/backends/firestore'
import { ALL_PROJECTS_INDEX, PROJECT_PRIVATE } from '../components/SettingsView/ProjectsSettings/ProjectHelper'
import store from '../redux/store'
import SharedHelper from '../utils/SharedHelper'
import {
    resetFloatPopup,
    setSelectedSidebarTab,
    setSelectedTypeOfProject,
    setShowAccessDeniedPopup,
    storeCurrentUser,
    switchProject,
} from '../redux/actions'
import { DV_TAB_ROOT_TASKS } from '../utils/TabNavigationConstants'
import { PROJECT_TYPE_ACTIVE } from '../components/SettingsView/ProjectsSettings/ProjectsSettings'
import NavigationService from '../utils/NavigationService'

export default function usePrivateProject(projectId) {
    const dispatch = useDispatch()

    const callback = project => {
        const { loggedUser } = store.getState()

        if (
            project &&
            project.isShared === PROJECT_PRIVATE &&
            (!project.userIds.includes(loggedUser.uid) || loggedUser.isAnonymous)
        ) {
            if (loggedUser.isAnonymous) {
                SharedHelper.redirectToPrivateResource()
            } else {
                dispatch([
                    resetFloatPopup(),
                    switchProject(ALL_PROJECTS_INDEX),
                    setSelectedSidebarTab(DV_TAB_ROOT_TASKS),
                    storeCurrentUser(loggedUser),
                    setSelectedTypeOfProject(PROJECT_TYPE_ACTIVE),
                    setShowAccessDeniedPopup(true),
                ])
                NavigationService.navigate('Root')
            }
        }
    }

    useEffect(() => {
        const watcherKey = v4()
        watchProject(projectId, callback, watcherKey)
        return () => {
            unwatch(watcherKey)
        }
    }, [projectId])

    return null
}
