import React from 'react'
import { Platform, StyleSheet, View } from 'react-native'

import SkillPresentation from '../../SkillItem/SkillPresentation'

export default function DraggableSkillActive({ projectId, skill, provided, isDragging, higherSkill }) {
    return (
        <div {...provided.draggableProps} ref={provided.innerRef}>
            <View>
                <SkillPresentation
                    projectId={projectId}
                    skill={skill}
                    higherSkill={higherSkill}
                    isDragging={isDragging}
                />
            </View>
        </div>
    )
}

const localStyle = StyleSheet.create({
    shadowSkill: {
        borderRadius: 4,
        backgroundColor: '#ffffff',
        ...Platform.select({
            web: {
                boxShadow: `${0}px ${8}px ${16}px rgba(0,0,0,0.04), ${0}px ${4}px ${8}px rgba(0,0,0,0.04)`,
            },
        }),
    },
})
