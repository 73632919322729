import React, { useEffect, useState } from 'react'
import Popover from 'react-tiny-popover'

import WorkflowOpen from './WorkflowOpen'
import EstimationModal from '../../UIComponents/FloatModals/EstimationModal/EstimationModal'
import { OPEN_STEP, TASK_ASSIGNEE_ASSISTANT_TYPE } from '../../TaskListView/Utils/TasksHelper'

export default function WorkflowOpenWrapper({ onStepPress, currentEstimation, task, projectId, disabled }) {
    const [showPopup, setShowPopup] = useState(false)
    const [selectedEstimation, setSelectedEstimation] = useState(currentEstimation)

    const closePopover = estimationValue => {
        if (estimationValue || estimationValue === 0) {
            setSelectedEstimation(estimationValue)
        }
        setShowPopup(false)
    }

    useEffect(() => {
        setSelectedEstimation(currentEstimation)
    }, [currentEstimation])

    const isCurrentStep = task.userIds.length === 1 && task.done === false
    const isAssistant = task.assigneeType === TASK_ASSIGNEE_ASSISTANT_TYPE

    return (
        <Popover
            content={
                <EstimationModal
                    task={task}
                    projectId={projectId}
                    stepId={OPEN_STEP}
                    closePopover={closePopover}
                    estimation={selectedEstimation}
                />
            }
            onClickOutside={() => {
                setShowPopup(false)
            }}
            isOpen={showPopup}
            padding={4}
            contentLocation={null}
        >
            <WorkflowOpen
                isCurrentStep={isCurrentStep}
                onStepPress={onStepPress}
                projectId={projectId}
                showModal={() => setShowPopup(true)}
                currentEstimation={selectedEstimation}
                disabled={disabled}
                disabledEstimation={isAssistant}
            />
        </Popover>
    )
}
