const Colors = {
    // Primary Colors
    White: '#FFFFFF',
    Black: '#000000',
    Transparent: 'transparent',

    Primary400: '#0A44A5',
    Primary350: '#0C4DBB',
    Primary300: '#0D55CF',
    Primary200: '#0C66FF',
    Primary100: '#007FFF',
    Primary300_Occlusion: '#2B64C5',

    WhiteGray: '#E3E5EA',
    GraySidebar: '#F5F5F5',
    Occlusion: 'rgba(138, 148, 166, 0.24)',

    // Secondary Colors
    Secondary400: '#091540',
    Secondary300: '#152560',
    Secondary250: '#172C78',
    Secondary200: '#1A3289',
    Secondary200_Desaturated: '#213683',
    Secondary100: '#4F66BA',

    // Text Colors
    Text01: '#04142F',
    Text01_Occlusion: '#24324C',
    Text02: '#4E5D78',
    Text03: '#8A94A6',
    Text04: '#B7BDC8',

    SecondaryButton: '#EAF0F5',
    SecondaryButton_Pressed: '#E3EBF2',
    SecondaryButton_Focus: '#F1F5F8',
    SecondaryButton_Default_Occlusion: '#D3DAE2',

    // UI Colors
    Grey500: '#718592',
    Grey400: '#C6CDD2',
    Grey350: '#D6DDE1',
    Grey300: '#E7ECEF',
    Grey200: '#F1F3F4',
    Grey100: '#FAFBFB',
    Grey400_Occlusion: '#B8BFC8',

    // Utility Colors
    UtilityBlue400: '#0051A3',
    UtilityBlue300: '#0070E0',
    UtilityBlue200: '#5AACFF',
    UtilityBlue150: '#A3D1FF',
    UtilityBlue125: '#C7E3FF',
    UtilityBlue112: '#D6EBFF',
    UtilityBlue100: '#EBF5FF',

    UtilityGreen400: '#057651',
    UtilityGreen300: '#07A873',
    UtilityGreen200: '#09D693',
    UtilityGreen150: '#7BEAC5',
    UtilityGreen125: '#ADF0D9',
    UtilityGreen112: '#C7F5E5',
    UtilityGreen100: '#EAFFF8',

    UtilityYellow400: '#A66007',
    UtilityYellow300: '#F58E0A',
    UtilityYellow200: '#FFAE47',
    UtilityYellow150: '#FFCE8F',
    UtilityYellow125: '#FFE6C7',
    UtilityYellow112: '#FFEDD6',
    UtilityYellow100: '#FFF6EB',

    UtilityRed400: '#8B181B',
    UtilityRed300: '#BD0303',
    UtilityRed200: '#E00000',
    UtilityRed150: '#FFA3A3',
    UtilityRed125: '#FFC7C7',
    UtilityRed112: '#FFD6D6',
    UtilityRed100: '#FFEBEB',
    UtilityRed200_Occlusion: '#CB2328',

    UtilityOrange400: '#9C3B11',
    UtilityOrange300: '#E64A19',
    UtilityOrange200: '#FF7043',
    UtilityOrange150: '#FFB59E',
    UtilityOrange125: '#FFD4C7',
    UtilityOrange112: '#FFE0D6',
    UtilityOrange100: '#FFE8E0',

    UtilityViolet400: '#401192',
    UtilityViolet300: '#702EE6',
    UtilityViolet200: '#8743FF',
    UtilityViolet150: '#A16BFF',
    UtilityViolet125: '#DBC7FF',
    UtilityViolet112: '#E5D6FF',
    UtilityViolet100: '#ECE0FF',

    UtilityLime300: '#A8A700',
    UtilityLime200: '#B5C200',
    UtilityLime150: '#CCDA49',
    UtilityLime125: '#E0EA80',
    UtilityLime112: '#EAF0AF',
    UtilityLime100: '#F0F3CE',

    UtilityPelorous300: '#0097A7',
    UtilityPelorous200: '#00ACC1',
    UtilityPelorous150: '#48CADA',
    UtilityPelorous125: '#80DEEA',
    UtilityPelorous112: '#B2EBF2',
    UtilityPelorous100: '#D8F4F8',

    UtilityLime2_300: '#FB8423',
    UtilityLime2_200: '#FB9A4B',
    UtilityLime2_150: '#FCB173',
    UtilityLime2_125: '#FDC79B',
    UtilityLime2_112: '#FEDDC3',
    UtilityLime2_100: '#FEE9D7',

    UtilityDarkMagenta300: '#E935CD',
    UtilityDarkMagenta200: '#F77EE4',
    UtilityDarkMagenta150: '#F9A4EC',
    UtilityDarkMagenta125: '#FCCAF4',
    UtilityDarkMagenta112: '#FFDBF9',
    UtilityDarkMagenta100: '#FFE6FB',

    UtilityDarkBlue300: '#0055FF',
    UtilityDarkBlue200: '#7AA7FF',
    UtilityDarkBlue150: '#B8CFFF',
    UtilityDarkBlue125: '#D6E3FF',
    UtilityDarkBlue112: '#E6EEFF',
    UtilityDarkBlue100: '#F0F4FF',

    UtilityForestGreen300: '#4BA72F',
    UtilityForestGreen200: '#59C738',
    UtilityForestGreen150: '#8DDB76',
    UtilityForestGreen125: '#AFE59E',
    UtilityForestGreen112: '#CAEEBF',
    UtilityForestGreen100: '#E4F6DF',

    Goal_20_04: '#BB1FFF',
    Goal_20_03: '#CD5AFF',
    Goal_20_02: '#E099FF',
    Goal_20_01: '#ECC2FF',

    Goal_40_04: '#7033FF',
    Goal_40_03: '#8B5AFF',
    Goal_40_02: '#B899FF',
    Goal_40_01: '#D4C2FF',

    Goal_60_04: '#3347FF',
    Goal_60_03: '#5A6AFF',
    Goal_60_02: '#99A3FF',
    Goal_60_01: '#C2C8FF',

    // People Skin
    Skin_A_150: '#C2956B',
    Skin_A_125: '#E0AD7B',
    Skin_A_100: '#FFC58C',

    Skin_B_150: '#D19886',
    Skin_B_125: '#F0B5A3',
    Skin_B_100: '#FFCDBD',

    Skin_C_150: '#664E45',
    Skin_C_125: '#8A695E',
    Skin_C_100: '#B88C7D',

    Skin_D_150: '#C27C55',
    Skin_D_125: '#E08F63',
    Skin_D_100: '#FFAD80',

    // Project Colors
    ProjectColor1000: '#E06EFD',
    ProjectColor900: '#B4E44E',
    ProjectColor800: '#45AFFC',
    ProjectColor700: '#FB70A1',
    ProjectColor600: '#1DE686',
    ProjectColor500: '#FDCB6E',
    ProjectColor400: '#00CEC9',
    ProjectColor300: '#7F71EA',
    ProjectColor200: '#E17055',
    ProjectColor100: '#06EEC1',

    // Marker Colors
    Marker_PrimaryBlue_01: '#47A3FF',
    Marker_PrimaryBlue_02: '#ADD6FF',
    Marker_PrimaryBlue_025: '#CCE6FF',
    Marker_PrimaryBlue_03: '#D6EBFF',
    Marker_PrimaryBlue_04: '#EBF5FF',
    Marker_PrimaryBlue_05: '#F5FAFF',
    Marker_PrimaryBlue_Dark: '#002952',

    Marker_Blue_01: '#47DEFF',
    Marker_Blue_02: '#BCE7F0',
    Marker_Blue_025: '#D5F0F6',
    Marker_Blue_03: '#DEF3F7',
    Marker_Blue_04: '#EFF9FB',
    Marker_Blue_05: '#F7FCFD',
    Marker_Blue_Dark: '#103841',

    Marker_Red_01: '#FF5274',
    Marker_Red_02: '#F0BCC7',
    Marker_Red_025: '#F6D5DC',
    Marker_Red_03: '#F7DEE3',
    Marker_Red_04: '#FBEFF1',
    Marker_Red_05: '#FDF7F8',
    Marker_Red_Dark: '#41101A',

    Marker_Purple_01: '#CB70FF',
    Marker_Purple_02: '#DDBCF0',
    Marker_Purple_025: '#EAD5F6',
    Marker_Purple_03: '#F2E6F9',
    Marker_Purple_04: '#F6EFFB',
    Marker_Purple_05: '#FBF7FD',
    Marker_Purple_Dark: '#2F1041',

    Marker_Green_01: '#00F477',
    Marker_Green_02: '#BCF0D6',
    Marker_Green_025: '#D1F5E3',
    Marker_Green_03: '#E2F8ED',
    Marker_Green_04: '#EFFBF5',
    Marker_Green_05: '#F7FDFA',
    Marker_Green_Dark: '#003D1E',

    Marker_Pink_01: '#FF70B3',
    Marker_Pink_02: '#F0BCD4',
    Marker_Pink_025: '#F6D5E4',
    Marker_Pink_03: '#F7DEEA',
    Marker_Pink_04: '#FBEFF4',
    Marker_Pink_05: '#FDF7FA',
    Marker_Pink_Dark: '#411027',

    Marker_Orange_01: '#FF925C',
    Marker_Orange_02: '#F4DACD',
    Marker_Orange_025: '#F6E0D5',
    Marker_Orange_03: '#F7E6DE',
    Marker_Orange_04: '#FBF3EF',
    Marker_Orange_05: '#FDF9F7',
    Marker_Orange_Dark: '#412110',

    Marker_Yellow_01: '#F0E219',
    Marker_Yellow_02: '#F0EDBC',
    Marker_Yellow_025: '#F2EFC4',
    Marker_Yellow_03: '#F3F1CD',
    Marker_Yellow_04: '#F9F8E7',
    Marker_Yellow_05: '#FCFBF3',
    Marker_Yellow_Dark: '#413E10',

    Marker_Pelorous_01: '#0AFFBE',
    Marker_Pelorous_02: '#BCF0E2',
    Marker_Pelorous_025: '#D5F6ED',
    Marker_Pelorous_03: '#DEF7F1',
    Marker_Pelorous_04: '#EFFBF8',
    Marker_Pelorous_05: '#F7FDFB',
    Marker_Pelorous_Dark: '#104134',

    Marker_Lime_01: '#BFFF0A',
    Marker_Lime_02: '#E3F0BC',
    Marker_Lime_025: '#EDF6D5',
    Marker_Lime_03: '#F1F7DE',
    Marker_Lime_04: '#F8FBEF',
    Marker_Lime_05: '#FBFDF7',
    Marker_Lime_Dark: '#354110',

    Marker_Violet_01: '#7C70FF',
    Marker_Violet_02: '#CCC9F3',
    Marker_Violet_025: '#D8D5F6',
    Marker_Violet_03: '#E0DEF7',
    Marker_Violet_04: '#F0EFFB',
    Marker_Violet_05: '#F7F7FD',
    Marker_Violet_Dark: '#141041',
}

export default Colors
