import React, { Component } from 'react'
import DueDateModal from '../UIComponents/FloatModals/DueDateModal/DueDateModal'
import Popover from 'react-tiny-popover'
import store from '../../redux/store'
import PropTypes from 'prop-types'
import Button from './Button'
import moment from 'moment'
import { hideFloatPopup, showFloatPopup } from '../../redux/actions'
import Hotkeys from 'react-hot-keys'
import { execShortcutFn } from '../../utils/HelperFunctions'
import { getDateFormat } from '../UIComponents/FloatModals/DateFormatPickerModal'
import { translate } from '../../i18n/TranslationService'

class DueDateButton extends Component {
    constructor(props) {
        super(props)
        const storeState = store.getState()

        this.state = {
            visiblePopover: false,
            smallScreen: storeState.smallScreen,
            currentUser: storeState.currentUser,
            unsubscribe: store.subscribe(this.updateState),
        }
    }

    componentWillUnmount() {
        this.state.unsubscribe()
    }

    updateState = () => {
        const storeState = store.getState()
        this.setState({
            smallScreen: storeState.smallScreen,
            currentUser: storeState.currentUser,
        })
    }

    hidePopover = () => {
        const { onDismissPopup } = this.props
        this.setState({ visiblePopover: false })
        store.dispatch(hideFloatPopup())
        if (onDismissPopup) onDismissPopup()
    }

    delayHidePopover = () => {
        // This timeout is necessary to stop the propagation of the click
        // to close the Modal, and reach the dismiss event of the EditTask
        setTimeout(async () => {
            this.hidePopover()
        })
    }

    showPopover = () => {
        /* istanbul ignore next */
        if (!this.state.visiblePopover) {
            this.setState({ visiblePopover: true })
            store.dispatch(showFloatPopup())
        }
    }

    getDueDateButtonText = () => {
        const { task, inEditTask, isObservedTask } = this.props
        const { smallScreen, currentUser } = this.state
        const today = moment()
        const dueDate = moment(isObservedTask ? task.dueDateByObserversIds[currentUser.uid] : task.dueDate)
        const inBacklog = isObservedTask
            ? task.dueDateByObserversIds[currentUser.uid] === Number.MAX_SAFE_INTEGER
            : task.dueDate === Number.MAX_SAFE_INTEGER

        return inEditTask && smallScreen
            ? null
            : inBacklog
            ? translate('Someday')
            : dueDate.isSame(today, 'day')
            ? translate('Today')
            : dueDate.format(getDateFormat(false, true))
    }

    render() {
        const {
            task,
            projectId,
            disabled,
            style,
            inEditTask,
            saveDueDateBeforeSaveTask,
            setToBacklogBeforeSaveTask,
            shortcutText,
            isObservedTask,
        } = this.props
        const { visiblePopover, smallScreen } = this.state

        return (
            <Popover
                content={
                    <DueDateModal
                        task={task}
                        projectId={projectId}
                        closePopover={this.hidePopover}
                        delayClosePopover={this.delayHidePopover}
                        inEditTask={inEditTask}
                        saveDueDateBeforeSaveTask={saveDueDateBeforeSaveTask}
                        setToBacklogBeforeSaveTask={setToBacklogBeforeSaveTask}
                        isObservedTask={isObservedTask}
                    />
                }
                onClickOutside={this.delayHidePopover}
                isOpen={visiblePopover}
                position={['bottom', 'left', 'right', 'top']}
                padding={4}
                align={'end'}
                contentLocation={smallScreen ? null : undefined}
            >
                <Hotkeys
                    keyName={`alt+${shortcutText}`}
                    disabled={disabled}
                    onKeyDown={(sht, event) => execShortcutFn(this.buttonRef, this.showPopover, event)}
                    filter={e => true}
                >
                    <Button
                        ref={ref => (this.buttonRef = ref)}
                        title={this.getDueDateButtonText()}
                        type={'ghost'}
                        noBorder={inEditTask && smallScreen}
                        icon={'calendar'}
                        buttonStyle={style}
                        onPress={this.showPopover}
                        disabled={disabled}
                        shortcutText={shortcutText}
                    />
                </Hotkeys>
            </Popover>
        )
    }
}

DueDateButton.propTypes = {
    task: PropTypes.object.isRequired,
    projectId: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    inEditTask: PropTypes.bool,
    saveDueDateBeforeSaveTask: PropTypes.func,
    shortcutText: PropTypes.string,
}

export default DueDateButton
