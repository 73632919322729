import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import OpenTasksViewAllProjects from '../OpenTasksView/OpenTasksViewAllProjects'
import AllProjectsEmptyInbox from './AllProjectsEmptyInbox'

export default function OpenTasksViewAllProjectsContainer() {
    const openTasksAmount = useSelector(state => state.openTasksAmount)
    const todayEmptyGoalsTotal = useSelector(state => state.todayEmptyGoalsTotalAmountInOpenTasksView.total)

    return (
        <View>
            {!openTasksAmount && !todayEmptyGoalsTotal && <AllProjectsEmptyInbox />}
            <OpenTasksViewAllProjects />
        </View>
    )
}
