import React, { useState } from 'react'
import Popover from 'react-tiny-popover'

import WorkflowStep from './WorkflowStep'
import EstimationModal from '../../UIComponents/FloatModals/EstimationModal/EstimationModal'

export default function WorkflowStepWrapper({
    onStepPress,
    currentEstimation,
    isCurrentStep,
    stepNumber,
    step,
    task,
    projectId,
    disabled,
}) {
    const [showPopup, setShowPopup] = useState(false)
    const [selectedEstimation, setSelectedEstimation] = useState(currentEstimation)

    const closePopover = estimationValue => {
        if (estimationValue || estimationValue === 0) {
            setSelectedEstimation(estimationValue)
        }
        setShowPopup(false)
    }

    return (
        <Popover
            content={
                <EstimationModal
                    task={task}
                    projectId={projectId}
                    stepId={step.id}
                    closePopover={closePopover}
                    estimation={selectedEstimation}
                />
            }
            onClickOutside={() => {
                setShowPopup(false)
            }}
            isOpen={showPopup}
            padding={4}
            contentLocation={null}
        >
            <WorkflowStep
                onPress={onStepPress}
                showModal={() => setShowPopup(true)}
                currentEstimation={selectedEstimation}
                isCurrentStep={isCurrentStep}
                stepNumber={stepNumber}
                step={step}
                projectId={projectId}
                disabled={disabled}
            />
        </Popover>
    )
}
