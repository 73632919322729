import React, { Component } from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import styles, { colors, windowTagStyle } from '../styles/global'
import Icon from '../Icon'
import store from '../../redux/store'
import PropTypes from 'prop-types'

class TaskSummarizeTags extends Component {
    constructor(props) {
        super(props)
        const storeState = store.getState()

        this.state = {
            smallScreenNavigation: storeState.smallScreenNavigation,
            unsubscribe: store.subscribe(this.updateState),
        }
    }

    componentWillUnmount() {
        this.state.unsubscribe()
    }

    updateState = () => {
        const storeState = store.getState()

        this.setState({
            smallScreenNavigation: storeState.smallScreenNavigation,
        })
    }

    render() {
        const { amountTags, style, onPress } = this.props

        return amountTags > 0 ? (
            <TouchableOpacity onPress={onPress}>
                <View style={[localStyles.container, style]}>
                    <Icon name={'tag'} size={16} color={colors.Text03} style={localStyles.icon} />
                    <Text style={[styles.subtitle2, localStyles.text, windowTagStyle()]}>{`${amountTags}`}</Text>
                </View>
            </TouchableOpacity>
        ) : null
    }
}

TaskSummarizeTags.propTypes = {
    amountTags: PropTypes.number.isRequired,
    style: PropTypes.object,
    onPress: PropTypes.func,
}

const localStyles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        backgroundColor: colors.Gray300,
        borderRadius: 12,
        alignItems: 'center',
        justifyContent: 'center',
        height: 24,
    },
    icon: {
        marginHorizontal: 4,
    },
    text: {
        color: colors.Text03,
        marginVertical: 1,
        marginRight: 10,
        marginLeft: 2,
    },
})

export default TaskSummarizeTags
