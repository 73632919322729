import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import v4 from 'uuid/v4'

import moment from 'moment'
import CustomScrollView from '../../../UIControls/CustomScrollView'
import TasksHelper, { OPEN_STEP, objectIsPublicForLoggedUser } from '../../../TaskListView/Utils/TasksHelper'
import store from '../../../../redux/store'
import Backend from '../../../../utils/BackendBridge'
import {
    hideFloatPopup,
    setSelectedSidebarTab,
    setSelectedTypeOfProject,
    setTasksArrowButtonIsExpanded,
    showFloatPopup,
    switchProject,
    updateTaskSuggestedCommentModalData,
} from '../../../../redux/actions'
import EstimationModal from '../EstimationModal/EstimationModal'
import DueDateModal from '../DueDateModal/DueDateModal'
import {
    FEED_ASSISTANT_OBJECT_TYPE,
    FEED_CHAT_OBJECT_TYPE,
    FEED_CONTACT_OBJECT_TYPE,
    FEED_GOAL_OBJECT_TYPE,
    FEED_NOTE_OBJECT_TYPE,
    FEED_OBJECT_NOTE_OBJECT_TYPE,
    FEED_PROJECT_OBJECT_TYPE,
    FEED_SKILL_OBJECT_TYPE,
    FEED_TASK_OBJECT_TYPE,
    FEED_USER_OBJECT_TYPE,
} from '../../../Feeds/Utils/FeedsConstants'
import ProjectHelper, { ALL_PROJECTS_INDEX } from '../../../SettingsView/ProjectsSettings/ProjectHelper'
import PrivacyModal from '../PrivacyModal/PrivacyModal'
import AssigneeAndObserversModal from '../AssigneeAndObserversModal/AssigneeAndObserversModal'
import {
    DV_TAB_CHAT_BOARD,
    DV_TAB_CONTACT_CHAT,
    DV_TAB_GOAL_CHAT,
    DV_TAB_NOTE_CHAT,
    DV_TAB_TASK_CHAT,
    DV_TAB_USER_CHAT,
    DV_TAB_SKILL_CHAT,
    DV_TAB_ROOT_TASKS,
    DV_TAB_ASSISTANT_CHAT,
} from '../../../../utils/TabNavigationConstants'
import {
    DATE_TASK_INDEX,
    EMPTY_SECTION_INDEX,
    MAIN_TASK_INDEX,
    NOT_PARENT_GOAL_INDEX,
    TODAY_DATE,
} from '../../../../utils/backends/openTasks'
import MainModal from './MainModal'
import TaskParentGoalModal from '../TaskParentGoalModal/TaskParentGoalModal'
import TaskMoreOptionModal from '../TaskMoreOptionsModal/TaskMoreOptionModal'
import { WORKSTREAM_ID_PREFIX } from '../../../Workstreams/WorkstreamHelper'
import { getDvTabLink } from '../../../../utils/LinkingHelper'
import { uploadNewTask } from '../../../../utils/backends/Tasks/tasksFirestore'

const buildLinkBack = (projectId, sourceType, sourceId, objectNoteType) => {
    const { selectedNavItem } = store.getState()

    const inChatTab =
        selectedNavItem === DV_TAB_TASK_CHAT ||
        selectedNavItem === DV_TAB_CONTACT_CHAT ||
        selectedNavItem === DV_TAB_USER_CHAT ||
        selectedNavItem === DV_TAB_CHAT_BOARD ||
        selectedNavItem === DV_TAB_NOTE_CHAT ||
        selectedNavItem === DV_TAB_SKILL_CHAT ||
        selectedNavItem === DV_TAB_GOAL_CHAT ||
        selectedNavItem === DV_TAB_ASSISTANT_CHAT

    let url = ''
    const tab = inChatTab
        ? 'chat'
        : sourceType === FEED_NOTE_OBJECT_TYPE
        ? 'editor'
        : sourceType === FEED_ASSISTANT_OBJECT_TYPE
        ? 'customizations'
        : 'properties'

    switch (sourceType) {
        case FEED_TASK_OBJECT_TYPE: {
            url = getDvTabLink(projectId, sourceId, 'tasks', tab)
            break
        }
        case FEED_PROJECT_OBJECT_TYPE: {
            url = getDvTabLink(projectId, sourceId, 'projects', 'properties')
            break
        }
        case FEED_CONTACT_OBJECT_TYPE: {
            url = getDvTabLink(projectId, sourceId, 'contacts', tab)
            break
        }
        case FEED_USER_OBJECT_TYPE: {
            url = getDvTabLink(projectId, sourceId, 'users', tab)
            break
        }
        case FEED_NOTE_OBJECT_TYPE: {
            url = getDvTabLink(projectId, sourceId, 'notes', tab)
            break
        }
        case FEED_OBJECT_NOTE_OBJECT_TYPE: {
            url = getDvTabLink(projectId, sourceId, objectNoteType, tab)
            break
        }
        case FEED_GOAL_OBJECT_TYPE: {
            url = getDvTabLink(projectId, sourceId, 'goals', tab)
            break
        }
        case FEED_CHAT_OBJECT_TYPE: {
            url = getDvTabLink(projectId, sourceId, 'chats', tab)
            break
        }
        case FEED_SKILL_OBJECT_TYPE: {
            url = getDvTabLink(projectId, sourceId, 'skills', tab)
            break
        }
        case FEED_ASSISTANT_OBJECT_TYPE: {
            url = getDvTabLink(projectId, sourceId, 'assistants', tab)
            break
        }
    }

    return url
}

const getNewInitialDefaultTask = (
    fromTaskList,
    projectId,
    sourceType,
    sourceId,
    sourceIsPublicFor,
    objectNoteType,
    lockKey,
    useLoggedUser
) => {
    const { loggedUser, currentUser } = store.getState()
    const user = fromTaskList && !useLoggedUser ? currentUser : loggedUser
    const uid = user.uid
    const newTask = TasksHelper.getNewDefaultTask()
    const taskName = fromTaskList
        ? ''
        : `${window.location.origin}${buildLinkBack(projectId, sourceType, sourceId, objectNoteType)} `
    newTask.name = taskName
    newTask.extendedName = taskName
    newTask.userId = uid
    newTask.currentReviewerId = uid
    newTask.userIds = [uid]
    if (sourceType === FEED_GOAL_OBJECT_TYPE && sourceIsPublicFor) {
        newTask.parentGoalId = sourceId
        newTask.parentGoalIsPublicFor = sourceIsPublicFor
        newTask.lockKey = lockKey ? lockKey : ''
    }
    if (loggedUser.uid !== uid && !user.recorderUserId && !uid.startsWith(WORKSTREAM_ID_PREFIX)) {
        newTask.suggestedBy = loggedUser.uid
    }
    return newTask
}

export default function RichCreateTaskModal({
    projectId,
    initialTask,
    setShowInteractionBar,
    triggerWhenCreateTask,
    sourceId,
    objectNoteType,
    sourceType,
    sourceIsPublicFor,
    lockKey,
    fromTaskList,
    closeModal,
    setPressedShowMoreMainSection,
    instanceKey,
    modalTitle,
    tryExpandTasksListInGoalWhenAddTask,
    useLoggedUser,
}) {
    const dispatch = useDispatch()
    const [activeGoal, setActiveGoal] = useState(null)
    const [showDueDateModal, setShowDueDateModal] = useState(false)
    const [showEstimationModal, setShowEstimationModal] = useState(false)
    const [showPrivacyModal, setShowPrivacyModal] = useState(false)
    const [showAssigneeModal, setShowAssigneeModal] = useState(false)
    const [showParentGoalModal, setShowParentGoalModal] = useState(false)
    const [showMoreOptionsModal, setShowMoreOptionsModal] = useState(false)

    const [task, setTask] = useState(
        initialTask
            ? initialTask
            : getNewInitialDefaultTask(
                  fromTaskList,
                  projectId,
                  sourceType,
                  sourceId,
                  sourceIsPublicFor,
                  objectNoteType,
                  lockKey,
                  useLoggedUser
              )
    )

    const showDueDate = () => {
        if (!showDueDateModal) {
            setShowDueDateModal(true)
            dispatch(showFloatPopup())
        }
    }

    const showEstimation = () => {
        if (!showEstimationModal) {
            setShowEstimationModal(true)
            dispatch(showFloatPopup())
        }
    }

    const showParentGoal = () => {
        if (!showParentGoalModal) {
            setShowParentGoalModal(true)
        }
    }

    const showMoreOptions = () => {
        if (!showMoreOptionsModal) {
            setShowMoreOptionsModal(true)
        }
    }

    const showPrivacy = () => {
        if (!showPrivacyModal) {
            setShowPrivacyModal(true)
            dispatch(showFloatPopup())
        }
    }

    const showAssignee = () => {
        if (!showAssigneeModal) {
            setShowAssigneeModal(true)
            store.dispatch(showFloatPopup())
        }
    }

    const saveDescription = description => {
        setTask({ ...task, description })
        setShowMoreOptionsModal(false)
    }

    const saveRecurrence = recurrence => {
        setTask({ ...task, recurrence })
        setShowMoreOptionsModal(false)
    }

    const saveHighlight = (e, data) => {
        e?.preventDefault()
        e?.stopPropagation()
        setTask({ ...task, hasStar: data.color })
        setShowMoreOptionsModal(false)
    }

    const saveEstimation = value => {
        if (value !== undefined) setTask({ ...task, estimations: { ...task.estimations, [OPEN_STEP]: value } })
        setShowEstimationModal(false)
        dispatch(hideFloatPopup())
    }

    const saveParentGoal = goal => {
        const parentGoalId = goal ? goal.id : null
        const parentGoalIsPublicFor = goal ? goal.isPublicFor : null
        const lockKey = goal && goal.lockKey ? goal.lockKey : ''
        setTask({ ...task, parentGoalId, parentGoalIsPublicFor, lockKey })
        setActiveGoal(goal)
        setShowParentGoalModal(false)
    }

    const savePrivacy = (isPrivate, isPublicFor) => {
        setTask({ ...task, isPrivate, isPublicFor })
        setShowPrivacyModal(false)
        dispatch(hideFloatPopup())
    }

    const saveDueDate = dueDate => {
        if (dueDate !== undefined) setTask({ ...task, dueDate })
        setShowDueDateModal(false)
        dispatch(hideFloatPopup())
    }

    const setToBacklog = () => {
        setTask({ ...task, dueDate: Number.MAX_SAFE_INTEGER })
        setShowDueDateModal(false)
        dispatch(hideFloatPopup())
    }

    const saveAssignee = (user, observers) => {
        const { uid } = user
        const { loggedUser } = store.getState()

        const updatedTask = { ...task }
        if (uid) {
            updatedTask.userId = uid
            updatedTask.userIds = [uid]
            updatedTask.currentReviewerId = uid
            updatedTask.observersIds = observers.map(user => user.uid)
            updatedTask.creatorId = uid
        }
        updatedTask.suggestedBy =
            loggedUser.uid !== uid && !user.recorderUserId && !uid.startsWith(WORKSTREAM_ID_PREFIX)
                ? loggedUser.uid
                : null
        setTask(updatedTask)
        setShowAssigneeModal(false)
        dispatch(hideFloatPopup())
    }

    const assignAndComment = (user, observers) => {
        const { uid } = user
        const { loggedUser } = store.getState()
        closeModal(task)

        const updatedTask = { ...task }
        if (uid) {
            updatedTask.userId = uid
            updatedTask.userIds = [uid]
            updatedTask.currentReviewerId = uid
            updatedTask.observersIds = observers.map(user => user.uid)
            updatedTask.creatorId = uid
            updatedTask.suggestedBy =
                loggedUser.uid !== uid && !user.recorderUserId && !uid.startsWith(WORKSTREAM_ID_PREFIX)
                    ? loggedUser.uid
                    : null
        }

        setTask(updatedTask)
        setShowAssigneeModal(false)
        dispatch(hideFloatPopup())

        if (fromTaskList && instanceKey) tryExpandTasksList(updatedTask)
        if (tryExpandTasksListInGoalWhenAddTask) tryExpandTasksListInGoalWhenAddTask(updatedTask)
        uploadNewTask(projectId, updatedTask, '', null, true).then(task => {
            dispatch(updateTaskSuggestedCommentModalData(true, projectId, task, task.extendedName))
        })
    }

    const delayClosePopup = () => {
        setTimeout(async () => {
            setShowEstimationModal(false)
            setShowPrivacyModal(false)
            setShowDueDateModal(false)
            setShowAssigneeModal(false)
            setShowParentGoalModal(false)
            setShowMoreOptionsModal(false)
            dispatch(hideFloatPopup())
        })
    }

    const createTask = trySetLinkedObjects => {
        if (task.name.length > 0) {
            closeModal(task)
            if (triggerWhenCreateTask) triggerWhenCreateTask()

            setShowInteractionBar && setShowInteractionBar(false)
            if (fromTaskList && instanceKey) tryExpandTasksList(task)
            if (tryExpandTasksListInGoalWhenAddTask) tryExpandTasksListInGoalWhenAddTask(task)
            return uploadNewTask(projectId, task, '', null, true).then(task => {
                trySetLinkedObjects(task)
                return {
                    ...task,
                    id: task.id,
                }
            })
        }
    }

    const expandTasks = () => {
        const { selectedProjectIndex, currentUser, smallScreenNavigation } = store.getState()

        const aProjectIsSelected = selectedProjectIndex > ALL_PROJECTS_INDEX
        if (aProjectIsSelected) {
            setPressedShowMoreMainSection(true)
        } else {
            const projectIndex = ProjectHelper.getProjectById(projectId).index
            const projectType = ProjectHelper.getTypeOfProject(currentUser, projectId)
            const actionsToDispatch = [
                setTasksArrowButtonIsExpanded(true),
                hideFloatPopup(),
                setSelectedSidebarTab(DV_TAB_ROOT_TASKS),
                switchProject(projectIndex),
                setSelectedTypeOfProject(projectType),
            ]

            if (smallScreenNavigation) actionsToDispatch.push(hideWebSideBar())

            dispatch(actionsToDispatch)
        }
    }

    const tryExpandTasksList = task => {
        const { loggedUser, currentUser, filteredOpenTasksStore } = store.getState()

        const inLoggedUserBoard = loggedUser.uid === currentUser.uid
        const isLoggedUserTask = task.userId === loggedUser.uid

        const dateIndex = 0
        const dateFormated = filteredOpenTasksStore[instanceKey][dateIndex][DATE_TASK_INDEX]
        const endDateDueDate = moment(task.dueDate).endOf('day').valueOf()
        const isCreatedInToday = dateFormated === TODAY_DATE && endDateDueDate === moment().endOf('day').valueOf()

        const isCreatedInMainDay = isCreatedInToday

        const isTemplateProject = loggedUser.templateProjectIds.includes(projectId)

        const emptyGoalsAmount = filteredOpenTasksStore[instanceKey][dateIndex][EMPTY_SECTION_INDEX].length
        const mainTasks = filteredOpenTasksStore[instanceKey][dateIndex][MAIN_TASK_INDEX]

        let inGoalsItemsAmount = emptyGoalsAmount
        let allItemsAmount = emptyGoalsAmount
        mainTasks.forEach(goalTasksData => {
            if (goalTasksData[0] !== NOT_PARENT_GOAL_INDEX) inGoalsItemsAmount += goalTasksData[1].length
            allItemsAmount += goalTasksData[1].length
        })

        const cannotShowAllTasks = !!loggedUser.numberTodayTasks
        const nextTaskIsHidden = isTemplateProject
            ? loggedUser.numberTodayTasks <= allItemsAmount
            : loggedUser.numberTodayTasks <= inGoalsItemsAmount

        const needToExpand =
            cannotShowAllTasks && inLoggedUserBoard && isLoggedUserTask && isCreatedInMainDay && nextTaskIsHidden

        if (needToExpand) expandTasks()
    }

    const setActiveGoalData = goal => {
        const isPublic = objectIsPublicForLoggedUser(goal)
        setActiveGoal(isPublic ? goal : null)
    }

    useEffect(() => {
        const { parentGoalId } = task

        if (parentGoalId) {
            const watcherKey = v4()
            Backend.watchGoal(projectId, parentGoalId, watcherKey, setActiveGoalData)
            return () => {
                Backend.unwatch(projectId, watcherKey)
            }
        }
    }, [task.parentGoalId])

    return (
        <CustomScrollView showsVerticalScrollIndicator={false}>
            {showDueDateModal ? (
                <DueDateModal
                    task={task}
                    projectId={projectId}
                    closePopover={delayClosePopup}
                    delayClosePopover={delayClosePopup}
                    inEditTask={false}
                    saveDueDateBeforeSaveTask={saveDueDate}
                    setToBacklogBeforeSaveTask={setToBacklog}
                />
            ) : showEstimationModal ? (
                <EstimationModal
                    task={task}
                    projectId={projectId}
                    closePopover={delayClosePopup}
                    setEstimationFn={saveEstimation}
                    showBackButton={true}
                    stepId={-1}
                    notUpdateTask={true}
                    estimation={task.estimations[OPEN_STEP]}
                />
            ) : showPrivacyModal ? (
                <PrivacyModal
                    object={task}
                    objectType={FEED_TASK_OBJECT_TYPE}
                    projectId={projectId}
                    closePopover={delayClosePopup}
                    delayClosePopover={delayClosePopup}
                    savePrivacyBeforeSaveObject={savePrivacy}
                />
            ) : showParentGoalModal ? (
                <TaskParentGoalModal
                    activeGoal={activeGoal}
                    setActiveGoal={saveParentGoal}
                    projectId={projectId}
                    closeModal={delayClosePopup}
                    ownerId={task.userId}
                />
            ) : showMoreOptionsModal ? (
                <TaskMoreOptionModal
                    saveDescription={saveDescription}
                    saveRecurrence={saveRecurrence}
                    saveHighlight={saveHighlight}
                    task={task}
                    projectId={projectId}
                    closeModal={delayClosePopup}
                />
            ) : showAssigneeModal ? (
                <AssigneeAndObserversModal
                    projectIndex={ProjectHelper.getProjectById(projectId).index}
                    object={task}
                    onSaveData={saveAssignee}
                    closePopover={delayClosePopup}
                    delayClosePopover={delayClosePopup}
                    inEditTask
                    updateTask={assignAndComment}
                />
            ) : (
                <MainModal
                    projectId={projectId}
                    closeModal={closeModal}
                    modalTitle={modalTitle}
                    task={task}
                    showAssigneeModal={showAssigneeModal}
                    showDueDate={showDueDate}
                    showEstimation={showEstimation}
                    showParentGoal={showParentGoal}
                    showPrivacy={showPrivacy}
                    showAssignee={showAssignee}
                    showMoreOptions={showMoreOptions}
                    createTask={createTask}
                    setTask={setTask}
                />
            )}
        </CustomScrollView>
    )
}
