import React, { Component } from 'react'
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import styles, { colors } from '../styles/global'
import Icon from '../Icon'
import store from '../../redux/store'
import EstimationModal from '../UIComponents/FloatModals/EstimationModal/EstimationModal'
import Button from '../UIControls/Button'
import Backend from '../../utils/BackendBridge'
import CloseButton from '../FollowUp/CloseButton'
import AttachmentsTag from '../FollowUp/AttachmentsTag'
import WorkflowSelection from './WorkflowSelection'
import TasksHelper, { DONE_STEP, OPEN_STEP } from '../TaskListView/Utils/TasksHelper'
import {
    applyPopoverWidth,
    chronoEntriesOrder,
    getWorkflowStepsIdsSorted,
    getCommentDirectionWhenMoveTaskInTheWorklfow,
    getWorkflowStepId,
} from '../../utils/HelperFunctions'
import { getEstimationIconByValue } from '../../utils/EstimationHelper'
import { STAYWARD_COMMENT, updateNewAttachmentsData } from '../Feeds/Utils/HelperFunctions'
import { startLoadingData } from '../../redux/actions'
import Shortcut, { SHORTCUT_LIGHT } from '../UIControls/Shortcut'
import Hotkeys from 'react-hot-keys'
import RichCommentModal from '../UIComponents/FloatModals/RichCommentModal/RichCommentModal'
import FileTag from '../Tags/FileTag'
import { MENTION_MODAL_ID, removeModal, storeModal, WORKFLOW_MODAL_ID } from '../ModalsManager/modalsManager'
import { isEqual } from 'lodash'
import { translate } from '../../i18n/TranslationService'
import { getUserPresentationData } from '../ContactsView/Utils/ContactsHelper'
import { moveTasksFromMiddleOfWorkflow, moveTasksFromOpen } from '../../utils/backends/Tasks/tasksFirestore'

export const WORKFLOW_FORWARD = 'FORWARD'
export const WORKFLOW_BACKWARD = 'BACKWARD'

export default class WorkflowModal extends Component {
    constructor(props) {
        super(props)
        const storeState = store.getState()

        this.state = {
            inComments: false,
            inEstimation: false,
            inWorkflowSelection: false,
            inCalendar: false,
            inEstimationReviewer: false,
            comment: '',
            mentions: [],
            files: [],
            isPrivate: false,
            hasKarma: false,
            estimation: this.props.task.estimations[OPEN_STEP],
            date: '',
            dateText: '',
            steps: this.props.workflow,
            selectedNextStep: OPEN_STEP,
            selectedPreviousStep: OPEN_STEP,
            currentStep: OPEN_STEP,
            selectedCustomStep: false,
            currentStepId: '',
            estimations: {},
            lockAction: false,
            currentUser: storeState.currentUser,
            smallScreenNavigation: storeState.smallScreenNavigation,
            unsubscribe: store.subscribe(this.updateState),
        }

        this.taskOwner = TasksHelper.getTaskOwner(this.props.task.userId, this.props.projectId)
    }

    componentDidMount() {
        storeModal(WORKFLOW_MODAL_ID)
        document.addEventListener('keydown', this.followUpModalOnEnter)

        const { workflow: steps, task, pending } = this.props
        if (steps) {
            if (pending) {
                this.isPendingComponentDidMount()
            } else {
                let currentStep
                let currentStepId = ''
                let selectedNextStep = ''
                let selectedPreviousStep = ''
                if (!this.isNotInReview()) {
                    const stepsEntries = Object.entries(steps).sort(chronoEntriesOrder)
                    for (currentStep = 0; currentStep < stepsEntries.length; ++currentStep) {
                        if (stepsEntries[currentStep][0] === task.stepHistory[task.stepHistory.length - 1]) {
                            selectedNextStep = currentStep + 1 < stepsEntries.length ? currentStep + 1 : DONE_STEP
                            selectedPreviousStep = currentStep - 1 > -1 ? currentStep - 1 : OPEN_STEP
                            currentStepId = stepsEntries[currentStep][0]
                            break
                        }
                    }

                    this.setState({
                        steps,
                        currentStep,
                        currentStepId,
                        selectedNextStep,
                        selectedPreviousStep,
                        estimations: { ...task.estimations },
                    })
                } else {
                    if (task.done) {
                        this.setState({
                            steps,
                            currentStep: DONE_STEP,
                            selectedNextStep: OPEN_STEP,
                            selectedPreviousStep: OPEN_STEP,
                            estimations: { ...task.estimations },
                        })
                    } else if (this.isToReview()) {
                        const stepsEntries = Object.entries(steps).sort(chronoEntriesOrder)
                        for (currentStep = 0; currentStep < stepsEntries.length; ++currentStep) {
                            if (stepsEntries[currentStep][0] === task.stepHistory[task.stepHistory.length - 1]) {
                                selectedNextStep = currentStep + 1 < stepsEntries.length ? currentStep + 1 : DONE_STEP
                                selectedPreviousStep = currentStep - 1 > -1 ? currentStep - 1 : OPEN_STEP

                                break
                            }
                        }
                        this.setState({
                            steps,
                            currentStep,
                            selectedNextStep,
                            selectedPreviousStep,
                            estimations: { ...task.estimations },
                        })
                    } else {
                        this.setState({
                            steps,
                            currentStep: OPEN_STEP,
                            selectedNextStep: 0,
                            selectedPreviousStep: 0,
                            estimations: { ...task.estimations },
                        })
                    }
                }
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { task } = this.props
        if (!isEqual(prevState.estimations, this.state.estimations) || !isEqual(prevProps.task, this.props.task)) {
            this.setState({
                estimations: { ...task.estimations },
            })
        }
    }

    isPendingComponentDidMount = () => {
        const { workflow: steps, task } = this.props

        const stepsEntries = Object.entries(steps).sort(chronoEntriesOrder)
        const currentStep = task.stepHistory.length - 2
        const selectedNextStep = currentStep + 1 < stepsEntries.length ? currentStep + 1 : DONE_STEP
        const selectedPreviousStep = currentStep - 1 > -1 ? currentStep - 1 : OPEN_STEP

        this.setState({
            steps,
            currentStep,
            selectedNextStep,
            selectedPreviousStep,
            estimations: { ...task.estimations },
        })
    }

    componentWillUnmount() {
        removeModal(WORKFLOW_MODAL_ID)
        document.removeEventListener('keydown', this.followUpModalOnEnter)
        this.state.unsubscribe()
    }

    updateState = () => {
        const storeState = store.getState()

        this.setState({
            currentUser: storeState.currentUser,
            smallScreenNavigation: storeState.smallScreenNavigation,
        })
    }

    getCommentAndFiles = (comment, mentions, isPrivate, hasKarma) => {
        this.setState({ comment, mentions, isPrivate, hasKarma, inComments: false })
    }

    closeCommentsPopover = e => {
        const { isQuillTagEditorOpen, openModals } = store.getState()
        if (!isQuillTagEditorOpen && !openModals[MENTION_MODAL_ID]) {
            if (e) {
                e.preventDefault()
                e.stopPropagation()
            }
            this.setState({ inComments: false })
        }
    }

    removeComment = () => {
        this.setState({ comment: '', mentions: [], files: [] })
    }

    removeFile = index => {
        const newFiles = [...this.state.files]
        newFiles.splice(index, 1)
        this.setState({ files: newFiles })
    }

    closeEstimationModal = estimationValue => {
        if (estimationValue !== undefined) {
            const { estimations } = this.state
            estimations[OPEN_STEP] = estimationValue
            this.setState({ estimation: estimationValue, estimations: estimations, inEstimation: false })
        }
        this.setState({ inEstimation: false })
    }

    closeEstimationReviewerModal = estimationValue => {
        if (estimationValue !== undefined) {
            const { estimations, steps, currentStep } = this.state

            const stepsEntries = Object.entries(steps).sort(chronoEntriesOrder)
            estimations[stepsEntries[currentStep][0]] = estimationValue

            this.setState({ estimations: estimations })
        }
        this.setState({ inEstimationReviewer: false })
    }

    closeWorkFlowSelection = () => {
        this.setState({ inWorkflowSelection: false })
    }

    onPressClose = () => {
        this.props.cancelPopover()
    }

    selectStep = (stepIndex, hideModal = false) => {
        this.setState({ selectedNextStep: stepIndex, inWorkflowSelection: hideModal, selectedCustomStep: true })
    }

    onDonePress = direction => {
        const { task, projectId, checkBoxId } = this.props
        const { steps, estimations, selectedNextStep, comment } = this.state
        store.dispatch(startLoadingData())

        updateNewAttachmentsData(projectId, comment).then(commentWithAttachments => {
            const { stepHistory } = task
            const stepsIds = getWorkflowStepsIdsSorted(steps)

            const stepToMoveIndex = direction === WORKFLOW_BACKWARD ? OPEN_STEP : selectedNextStep
            const stepToMoveId = getWorkflowStepId(stepToMoveIndex, stepsIds)
            const commentType =
                commentWithAttachments && commentWithAttachments.length > 0
                    ? getCommentDirectionWhenMoveTaskInTheWorklfow(stepToMoveIndex, stepsIds, stepHistory)
                    : STAYWARD_COMMENT

            if (task.userIds.length === 1) {
                moveTasksFromOpen(
                    projectId,
                    task,
                    stepToMoveId,
                    commentWithAttachments,
                    commentType,
                    estimations,
                    checkBoxId
                )
            } else {
                moveTasksFromMiddleOfWorkflow(
                    projectId,
                    task,
                    stepToMoveId,
                    commentWithAttachments,
                    commentType,
                    estimations,
                    checkBoxId
                )
            }
        })

        this.props.hidePopover()
    }

    followUpModalOnEnter = e => {
        const { inComments, inEstimation, inWorkflowSelection, inCalendar, inEstimationReviewer } = this.state

        if (e.key === 'Enter' && !inComments) {
            if (inEstimation || inWorkflowSelection || inCalendar || inEstimationReviewer) {
                this.setState({
                    inComments: false,
                    inEstimation: false,
                    inWorkflowSelection: false,
                    inCalendar: false,
                    inEstimationReviewer: false,
                })
            } else {
                this.onDonePress(WORKFLOW_FORWARD)
            }
        }
    }

    getStepDataForTag = assignee => {
        const { steps, selectedNextStep } = this.state
        if (selectedNextStep === OPEN_STEP) {
            return { nextStepDescription: 'Open', nextStepPhotoURL: assignee.photoURL }
        } else if (selectedNextStep === DONE_STEP) {
            return { nextStepDescription: 'Done', nextStepPhotoURL: '' }
        } else {
            const stepsData = Object.entries(steps).sort(chronoEntriesOrder)[selectedNextStep]

            return {
                nextStepDescription: stepsData[1].description,
                nextStepPhotoURL: getUserPresentationData(stepsData[1].reviewerUid).photoURL,
            }
        }
    }

    isToReview() {
        const { task } = this.props
        const storeState = store.getState()
        return task.userIds.length > 1 && task.userIds[task.userIds.length - 1] === storeState.currentUser.uid
    }

    isNotInReview() {
        const { task } = this.props
        const storeState = store.getState()
        return task.userIds.length === 1 || task.userIds[task.userIds.length - 1] !== storeState.currentUser.uid
    }

    openCommentsPopover = e => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({ inComments: true })
    }

    commentShortcut = (sht, event) => {
        if (event != null) {
            event.preventDefault()
            event.stopPropagation()
        }
        this.setState({ inComments: true })
    }

    render() {
        const {
            comment,
            mentions,
            files,
            isPrivate,
            hasKarma,
            selectedNextStep,
            selectedCustomStep,
            estimations,
            currentStep,
            lockAction,
            steps,
            currentUser,
            smallScreenNavigation: mobile,
        } = this.state
        const { task, pending, projectId, ownerIsWorkstream } = this.props
        const { nextStepDescription, nextStepPhotoURL } = this.getStepDataForTag(this.taskOwner)
        const ownerId = ownerIsWorkstream ? store.getState().loggedUser.uid : task.userId

        const toReview =
            task.userIds.length > 1 && task.userIds[task.userIds.length - 1] === currentUser.uid && task.done === false

        const currentStepPhotoURL =
            currentStep !== OPEN_STEP
                ? getUserPresentationData(Object.entries(steps).sort(chronoEntriesOrder)[currentStep][1].reviewerUid)
                      .photoURL
                : ''

        return this.state.inComments ? (
            <RichCommentModal
                projectId={projectId}
                objectType={'tasks'}
                objectId={task.id}
                closeModal={this.closeCommentsPopover}
                processDone={this.getCommentAndFiles}
                currentComment={comment}
                currentMentions={mentions}
                currentPrivacy={isPrivate}
                currentKarma={hasKarma}
                inTaskModal={true}
                userGettingKarmaId={ownerId}
                externalAssistantId={task.assistantId}
            />
        ) : this.state.inEstimation ? (
            <EstimationModal
                task={task}
                projectId={this.props.projectId}
                closePopover={this.closeEstimationModal}
                showBackButton={true}
                stepId={-1}
                notUpdateTask={true}
                estimation={estimations[OPEN_STEP]}
            />
        ) : this.state.inEstimationReviewer ? (
            <EstimationModal
                task={task}
                projectId={this.props.projectId}
                closePopover={this.closeEstimationReviewerModal}
                showBackButton={true}
                stepId={task.stepHistory[task.stepHistory.length - 1]}
                notUpdateTask={true}
                estimation={estimations[task.stepHistory[task.stepHistory.length - 1]]}
            />
        ) : this.state.inWorkflowSelection ? (
            <WorkflowSelection
                closePopover={this.closeWorkFlowSelection}
                steps={this.props.workflow}
                task={task}
                assignee={this.taskOwner}
                selectedNextStep={selectedNextStep}
                selectStep={this.selectStep}
                estimations={estimations}
                currentStep={currentStep}
            />
        ) : (
            <View style={[localStyles.container, applyPopoverWidth()]}>
                <View style={localStyles.innerContainer}>
                    <View style={localStyles.heading}>
                        <View style={localStyles.title}>
                            <Text style={[styles.title7, { color: 'white' }]}>
                                {translate(pending ? 'Accept task?' : 'Congrats, you have done it!')}
                            </Text>
                            <Text style={[styles.body2, { color: colors.Text03, width: 273 }]}>
                                {translate('Select from the options below')}
                            </Text>
                        </View>
                        <CloseButton close={this.onPressClose} />
                    </View>

                    <View style={localStyles.subsection}>
                        <View style={[localStyles.estimationSection, { flexDirection: 'column' }]}>
                            <Hotkeys keyName={'1'} onKeyDown={this.commentShortcut} filter={e => true}>
                                <TouchableOpacity style={localStyles.estimation} onPress={this.openCommentsPopover}>
                                    <Icon name="message-circle" size={24} color="white" />
                                    <Text style={[styles.subtitle1, localStyles.uploadText]}>
                                        {translate('Add comment')}
                                    </Text>
                                    <View style={{ marginLeft: 'auto' }}>
                                        {!mobile ? (
                                            <Shortcut text={'1'} theme={SHORTCUT_LIGHT} />
                                        ) : (
                                            <Icon name={'chevron-right'} size={24} color={colors.Text03} />
                                        )}
                                    </View>
                                </TouchableOpacity>
                            </Hotkeys>

                            {comment !== '' || files.length > 0 ? (
                                <View style={localStyles.commentSection}>
                                    {comment !== '' ? (
                                        <View style={{ marginRight: 4 }}>
                                            <AttachmentsTag
                                                text={comment.substring(0, 20)}
                                                removeTag={this.removeComment}
                                                ico="message-circle"
                                                maxWidth={133}
                                            />
                                        </View>
                                    ) : null}

                                    {files.map((file, i) => {
                                        return (
                                            <View style={{ marginRight: i % 2 === 0 ? 0 : 4, marginBottom: 8 }}>
                                                <FileTag
                                                    key={i}
                                                    file={file}
                                                    canBeRemoved={true}
                                                    onCloseFile={() => this.removeFile(i)}
                                                    textStyle={{ maxWidth: 80 }}
                                                />
                                            </View>
                                        )
                                    })}
                                </View>
                            ) : null}
                        </View>

                        {!pending && !toReview && (
                            <View style={[localStyles.estimationSection, localStyles.estimationContainer]}>
                                <Hotkeys
                                    keyName={'2'}
                                    onKeyDown={() => this.setState({ inEstimation: true })}
                                    filter={e => true}
                                >
                                    <TouchableOpacity
                                        style={localStyles.estimation}
                                        onPress={() => this.setState({ inEstimation: true })}
                                    >
                                        <Icon
                                            name={`count-circle-${getEstimationIconByValue(
                                                projectId,
                                                estimations[OPEN_STEP]
                                            )}`}
                                            size={24}
                                            color="white"
                                        />
                                        <Text style={[styles.subtitle1, localStyles.uploadText]}>
                                            {translate(pending ? 'Change task estimation' : 'Change estimation')}
                                        </Text>
                                        {currentStep !== OPEN_STEP ? (
                                            <Image
                                                style={localStyles.userImage}
                                                source={{ uri: this.taskOwner.photoURL }}
                                            />
                                        ) : null}

                                        <View style={{ marginLeft: 'auto' }}>
                                            {!mobile ? (
                                                <Shortcut text={'2'} theme={SHORTCUT_LIGHT} />
                                            ) : (
                                                <Icon name={'chevron-right'} size={24} color={colors.Text03} />
                                            )}
                                        </View>
                                    </TouchableOpacity>
                                </Hotkeys>
                            </View>
                        )}

                        {currentStep !== OPEN_STEP ? (
                            <View style={[localStyles.estimationSection, localStyles.estimationContainer]}>
                                <Hotkeys
                                    keyName={!pending && !toReview ? '3' : '2'}
                                    onKeyDown={() => this.setState({ inEstimationReviewer: true })}
                                    filter={e => true}
                                >
                                    <TouchableOpacity
                                        style={localStyles.estimation}
                                        onPress={() => this.setState({ inEstimationReviewer: true })}
                                    >
                                        <Icon
                                            name={`count-circle-${getEstimationIconByValue(
                                                projectId,
                                                estimations[task.stepHistory[task.stepHistory.length - 1]]
                                                    ? estimations[task.stepHistory[task.stepHistory.length - 1]]
                                                    : 0
                                            )}`}
                                            size={24}
                                            color="white"
                                        />
                                        <Text style={[styles.subtitle1, localStyles.uploadText]}>
                                            {translate('Change step estimation')}
                                        </Text>
                                        <Image style={localStyles.userImage} source={{ uri: currentStepPhotoURL }} />
                                        <View style={{ marginLeft: 'auto' }}>
                                            {!mobile ? (
                                                <Shortcut
                                                    text={!pending && !toReview ? '3' : '2'}
                                                    theme={SHORTCUT_LIGHT}
                                                />
                                            ) : (
                                                <Icon name={'chevron-right'} size={24} color={colors.Text03} />
                                            )}
                                        </View>
                                    </TouchableOpacity>
                                </Hotkeys>
                            </View>
                        ) : null}

                        <View style={localStyles.itemsContainer}>
                            <Hotkeys
                                keyName={
                                    !pending && !toReview
                                        ? currentStep !== OPEN_STEP
                                            ? '4'
                                            : '3'
                                        : currentStep !== OPEN_STEP
                                        ? '3'
                                        : '2'
                                }
                                onKeyDown={() => this.setState({ inWorkflowSelection: true })}
                                filter={e => true}
                            >
                                <TouchableOpacity
                                    style={localStyles.estimation}
                                    onPress={() => this.setState({ inWorkflowSelection: true })}
                                >
                                    <Icon name="next-workflow" size={24} color="white" />
                                    <Text style={[styles.subtitle1, localStyles.uploadText]}>
                                        {translate(
                                            currentStep === OPEN_STEP ? 'Select next step' : 'Change workflow step'
                                        )}
                                    </Text>
                                    <View style={{ marginLeft: 'auto' }}>
                                        {!mobile ? (
                                            <Shortcut
                                                text={
                                                    !pending && !toReview
                                                        ? currentStep !== OPEN_STEP
                                                            ? '4'
                                                            : '3'
                                                        : currentStep !== OPEN_STEP
                                                        ? '3'
                                                        : '2'
                                                }
                                                theme={SHORTCUT_LIGHT}
                                            />
                                        ) : (
                                            <Icon name={'chevron-right'} size={24} color={colors.Text03} />
                                        )}
                                    </View>
                                </TouchableOpacity>
                            </Hotkeys>
                            {selectedCustomStep && (
                                <View style={{ marginTop: 10, alignSelf: 'flex-start', flexDirection: 'row' }}>
                                    <AttachmentsTag text={nextStepDescription} imageUrl={nextStepPhotoURL} />
                                </View>
                            )}
                        </View>
                        <View style={localStyles.doneButtonContainer}>
                            <View style={{ flexDirection: 'row' }}>
                                {currentStep !== OPEN_STEP && !selectedCustomStep && (
                                    <Hotkeys
                                        keyName={'alt+x'}
                                        onKeyDown={() => {
                                            setTimeout(() => this.onDonePress(WORKFLOW_BACKWARD), 100)
                                        }}
                                        filter={e => true}
                                    >
                                        <Button
                                            title={translate('Send back')}
                                            type={'secondary'}
                                            disabled={lockAction}
                                            onPress={() => {
                                                setTimeout(() => this.onDonePress(WORKFLOW_BACKWARD), 100)
                                            }}
                                            shortcutText={'X'}
                                            shortcutStyle={{ backgroundColor: colors.Secondary200 }}
                                            buttonStyle={{ marginRight: 8 }}
                                        />
                                    </Hotkeys>
                                )}

                                <Button
                                    title={translate(
                                        currentStep === OPEN_STEP
                                            ? 'Go to next step'
                                            : !selectedCustomStep
                                            ? 'Send forward'
                                            : 'Send to custom step'
                                    )}
                                    type={'primary'}
                                    disabled={lockAction}
                                    onPress={() => {
                                        setTimeout(() => this.onDonePress(WORKFLOW_FORWARD), 100)
                                    }}
                                    shortcutText={'Enter'}
                                    shortcutStyle={{ backgroundColor: colors.Secondary200 }}
                                />
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        )
    }
}

const localStyles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        width: 305,
        shadowColor: 'rgba(78, 93, 120, 0.56)',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 1,
        shadowRadius: 16,
        elevation: 3,
    },
    innerContainer: {
        flexDirection: 'column',
        borderRadius: 4,
        backgroundColor: colors.Secondary400,
    },
    heading: {
        flexDirection: 'row',
        paddingLeft: 16,
        paddingTop: 8,
        paddingRight: 8,
    },
    title: {
        flexDirection: 'column',
        marginTop: 8,
    },
    estimationContainer: {
        borderTopColor: colors.funnyWhite,
        borderTopWidth: 1,
    },
    itemsContainer: {
        borderTopColor: colors.funnyWhite,
        borderTopWidth: 1,
        borderBottomColor: colors.funnyWhite,
        borderBottomWidth: 1,
        paddingVertical: 8,
        marginHorizontal: -16,
        paddingHorizontal: 16,
    },
    subsection: {
        marginTop: 20,
        paddingHorizontal: 16,
    },
    upload: {
        height: 40,
        flexDirection: 'row',
        alignItems: 'center',
    },
    uploadText: {
        color: 'white',
        marginLeft: 8,
    },
    doneButtonContainer: {
        height: 72,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 16,
        paddingBottom: 16,
    },
    estimation: {
        height: 40,
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
    },
    estimationSection: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 8,
        marginHorizontal: -16,
        paddingHorizontal: 16,
    },
    userImage: {
        width: 24,
        height: 24,
        borderRadius: 100,
        marginLeft: 8,
    },
    commentSection: {
        marginTop: 10,
        alignSelf: 'flex-start',
        flexDirection: 'row',
        overflow: 'hidden',
        flexWrap: 'wrap',
    },
})
