import React, { useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'

import DueDateModal from '../UIComponents/FloatModals/DueDateModal/DueDateModal'
import Popover from 'react-tiny-popover'
import { hideFloatPopup, hideSwipeDueDatePopup, setSwipeDueDatePopupData } from '../../redux/actions'
import Backend from '../../utils/BackendBridge'

export default function DueDateSinglePopup() {
    const dispatch = useDispatch()
    const currentUserId = useSelector(state => state.currentUser.uid)
    const smallScreenNavigation = useSelector(state => state.smallScreenNavigation)
    const data = useSelector(state => state.showSwipeDueDatePopup.data)
    const [visibleCalendar, setVisibleCalendar] = useState(false)

    const { task, projectId, isObservedTask, multipleTasks, goal, parentGoaltasks, inParentGoal, isEmptyGoal } = data

    const hidePopover = () => {
        if (!visibleCalendar) dispatch([hideFloatPopup(), hideSwipeDueDatePopup(), setSwipeDueDatePopupData(null)])
    }

    const delayHidePopover = () => {
        // This timeout is necessary to stop the propagation of the click
        // to close the Modal, and reach the dismiss event of the EditTask
        setTimeout(async () => {
            hidePopover()
        })
    }

    const hideCalendar = () => {
        setVisibleCalendar(false)
    }

    const showCalendar = () => {
        setVisibleCalendar(true)
    }

    const updateParentGoalReminderDate = date => {
        Backend.updateGoalAssigneeReminderDate(projectId, goal.id, currentUserId, date)
    }

    let sidebarOpenStyle = smallScreenNavigation ? null : { marginLeft: 300 }

    return (
        <View style={localStyles.container}>
            <View style={[localStyles.popup, sidebarOpenStyle]}>
                <Popover
                    content={
                        <DueDateModal
                            task={task}
                            projectId={projectId}
                            closePopover={delayHidePopover}
                            delayClosePopover={delayHidePopover}
                            hideCalendar={hideCalendar}
                            showCalendar={showCalendar}
                            isObservedTask={isObservedTask}
                            multipleTasks={multipleTasks}
                            tasks={parentGoaltasks}
                            inParentGoal={inParentGoal}
                            updateParentGoalReminderDate={goal ? updateParentGoalReminderDate : undefined}
                            goalCompletionDate={goal ? goal.completionMilestoneDate : undefined}
                            goalStartingDate={goal ? goal.startingMilestoneDate : undefined}
                            goal={goal}
                        />
                    }
                    onClickOutside={delayHidePopover}
                    isOpen={true}
                    padding={4}
                    contentLocation={smallScreenNavigation ? null : undefined}
                >
                    <Text />
                </Popover>
            </View>
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        position: 'absolute',
        zIndex: 10000,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'transparent',
        justifyContent: 'center',
        alignItems: 'center',
    },
    popup: {
        alignItems: 'center',
    },
})
