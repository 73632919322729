import React from 'react'
import { Image, StyleSheet, View } from 'react-native'

import useCollapsibleSidebar from '../../Collapsible/UseCollapsibleSidebar'
import UserName from './UserName'
import SVGGenericUser from '../../../../assets/svg/SVGGenericUser'

export default function AssistantData({ assistant }) {
    const { expanded } = useCollapsibleSidebar()
    const { photoURL50, uid, displayName } = assistant

    return (
        <View style={localStyles.container}>
            {!photoURL50 ? (
                <View style={[localStyles.image, localStyles.avatar, { marginRight: expanded ? 10 : 2 }]}>
                    <SVGGenericUser width={20} height={20} svgid={`ci_p_${assistant.uid}`} />
                </View>
            ) : (
                <Image source={{ uri: photoURL50 }} style={[localStyles.image, { marginRight: expanded ? 10 : 2 }]} />
            )}

            {expanded && <UserName userId={uid} name={displayName} containerStyle={{ marginRight: 5 }} />}
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    image: {
        height: 20,
        width: 20,
        borderRadius: 100,
    },
    avatar: {
        overflow: 'hidden',
    },
})
