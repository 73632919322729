import React from 'react'
import { StyleSheet, View } from 'react-native'
import PendingTasksByProject from './PendingTasksByProject'
import { sortBy } from 'lodash'
import { useSelector } from 'react-redux'
import NothingToShow from '../../UIComponents/NothingToShow'

export default function PendingTasksViewAllProjects({ workflowTasksAmount }) {
    const mobile = useSelector(state => state.smallScreenNavigation)
    const isMiddleScreen = useSelector(state => state.isMiddleScreen)
    const archivedProjectIds = useSelector(state => state.loggedUser.archivedProjectIds)
    const templateProjectIds = useSelector(state => state.loggedUser.templateProjectIds)

    const sortedLoggedUserProjects = useSelector(state =>
        sortBy(state.loggedUserProjects, [project => project.name.toLowerCase()])
    )

    const projects = sortedLoggedUserProjects.filter(
        project => !templateProjectIds.includes(project.id) && !archivedProjectIds.includes(project.id)
    )

    return (
        <View>
            <View
                style={[
                    localStyles.container,
                    mobile ? localStyles.containerForMobile : isMiddleScreen && localStyles.containerForTablet,
                ]}
            >
                {projects.map(project => (
                    <PendingTasksByProject key={project.id} project={project} />
                ))}
            </View>
            {workflowTasksAmount === 0 && <NothingToShow />}
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        paddingHorizontal: 104,
        backgroundColor: 'white',
    },
    containerForMobile: {
        paddingHorizontal: 16,
    },
    containerForTablet: {
        paddingHorizontal: 56,
    },
})
