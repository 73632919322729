import React from 'react'
import { Text, View } from 'react-native'
import { useSelector } from 'react-redux'

import styles, { colors } from '../../styles/global'
import Icon from '../../Icon'
import { translate } from '../../../i18n/TranslationService'

export default function AllProjectsEmptyInboxText() {
    const isMiddleScreen = useSelector(state => state.isMiddleScreen)

    return (
        <View style={[localStyles.emptyInboxText, isMiddleScreen ? localStyles.emptyInboxTextMobile : undefined]}>
            <Icon name={'info'} size={22} color={colors.Text03} style={{ marginRight: 9 }} />
            <Text style={[styles.body1, { color: colors.Text02, textAlign: 'center' }]}>
                {translate('Congrats - you have reached empty inbox across all your projects') +
                    '\n' +
                    translate('Please select a project to add a new task')}
            </Text>
        </View>
    )
}

const localStyles = {
    emptyInboxText: {
        marginTop: 32,
        maxWidth: 700,
        marginHorizontal: 104,
        alignItems: 'flex-start',
        flexDirection: 'row',
    },
    emptyInboxTextMobile: {
        marginHorizontal: 16,
    },
}
