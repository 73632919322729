import React from 'react'
import { useSelector } from 'react-redux'
import { sortBy, isEqual } from 'lodash'

import ProjectList from './ProjectList'
import { PROJECT_TYPE_ACTIVE } from '../SettingsView/ProjectsSettings/ProjectsSettings'
import ProjectHelper from '../SettingsView/ProjectsSettings/ProjectHelper'

export default function ActiveProjectsList({ navigation }) {
    const loggedUserProjectsData = useSelector(
        state =>
            state.loggedUserProjects.map(project => {
                const { id, name, color, index, parentTemplateId, globalAssistantIds } = project
                return { id, name, color, index, parentTemplateId, globalAssistantIds }
            }),
        isEqual
    )
    const projectIds = useSelector(state => state.loggedUser.projectIds)
    const archivedProjectIds = useSelector(state => state.loggedUser.archivedProjectIds)
    const templateProjectIds = useSelector(state => state.loggedUser.templateProjectIds)
    const guideProjectIds = useSelector(state => state.loggedUser.guideProjectIds)

    const activeProjectsData = ProjectHelper.getActiveProjectsInList(
        loggedUserProjectsData,
        projectIds,
        archivedProjectIds,
        templateProjectIds,
        guideProjectIds
    )
    const sortedProjectsData = sortBy(activeProjectsData, [projectData => projectData.name.toLowerCase()])

    return <ProjectList projectsData={sortedProjectsData} projectType={PROJECT_TYPE_ACTIVE} navigation={navigation} />
}
